import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { getAllTasks } from './api';
import TeamSelector from '../Layout/TeamSelector';
import { useState, useEffect, useRef } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import TasksTable from '../shared/TasksTable';
import { sendPostRequest } from '../../utils/network';
import { Button } from '@mui/material';
import SavedViews from './SavedViews';
import SaveViewModal from './SaveViewModal';
import Toast from '../Layout/Toast';
import { deepEqual, flattenTaskData } from './helpers';
import dayjs from 'dayjs';

export default function Tasks() {
  const userQawId = localStorage.getItem('userId');
  const [enabledTeams, setEnabledTeams] = useState([]);
  const [toast, setToast] = useState(<></>);
  const defaultTableState = {
    columnFilters: [],
    columnFilterFn: {},
    columnOrder: [],
    columnPinning: {
      left: ['mrt-row-expand', 'mrt-row-select'],
    },
    columnSizing: {},
    columnVisibility: {},
    globalFilter: '',
    globalFilterFn: ['contains'],
    groupCRandMRs: false,
    pagination: {
      pageIndex: 0,
      pageSize: 100,
    },
    rowSelection: {},
    sorting: [],
  };
  const [tableState, setTableState] = useState(defaultTableState);
  const [selectedView, setSelectedView] = useState(0);
  const [draftName, setDraftName] = useState('');
  const [openViewNameModal, setOpenViewNameModal] = useState(false);

  // React Router hooks
  const location = useLocation();
  const navigate = useNavigate(); // For React Router v6

  // Ref to prevent looping
  const isInitialLoad = useRef(true);

  // Update URL search parameters
  useEffect(() => {
    // Do not set search params on initial load
    if (isInitialLoad.current) {
      isInitialLoad.current = false;
      return;
    }

    const searchParams = new URLSearchParams(location.search);

    Object.entries(tableState).forEach(([key, value]) => {
      if (
        value === undefined ||
        value === null ||
        (Array.isArray(value) && value.length === 0) ||
        (typeof value === 'object' && !Array.isArray(value) && Object.keys(value).length === 0) ||
        (typeof value === 'string' && value.trim() === '') ||
        deepEqual(value, defaultTableState[key])
      ) {
        searchParams.delete(key); // Delete key if the value is empty
      } else {
        if (typeof value === 'object') {
          searchParams.set(key, JSON.stringify(value));
        } else {
          searchParams.set(key, value.toString());
        }
      }
    });

    // Handle 'selectedView' separately
    if (selectedView) {
      searchParams.set('selectedView', JSON.stringify(selectedView));
    } else {
      searchParams.delete('selectedView');
    }

    // Handle enabledTeams separately
    if (enabledTeams && enabledTeams.length) {
      let teamIds = enabledTeams.map((team) => team.id);

      // Set local storage
      let teamsInStorage = JSON.parse(localStorage.getItem('teams'));
      let updatedTeamsInStorage = teamsInStorage.map((team) => {
        return {
          ...team,
          active: teamIds.includes(team.id),
        };
      });
      localStorage.setItem('teams', JSON.stringify(updatedTeamsInStorage));

      // Set search params
      searchParams.set('enabledTeams', JSON.stringify(teamIds));
    } else {
      searchParams.delete('enabledTeams');
    }

    // Update the URL with the new search params
    navigate(
      {
        pathname: location.pathname,
        search: searchParams.toString(),
      },
      { replace: true },
    );
  }, [tableState, selectedView, enabledTeams]);

  // Set table state based on URL on initial load
  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);

    const newTableState = { ...defaultTableState };

    searchParams.forEach((value, key) => {
      try {
        // Handle 'selectedView' separately from tableState
        if (key === 'selectedView') {
          setSelectedView(JSON.parse(value));
        }
        // Handle 'enabledTeams" separately from tableState
        else if (key === 'enabledTeams') {
          let teamsInStorage = JSON.parse(localStorage.getItem('teams'));
          let teamIDsFromParams = JSON.parse(value);

          // Set local storage
          let updatedTeamsInStorage = teamsInStorage.map((team) => {
            return {
              ...team,
              active: teamIDsFromParams.includes(team.id),
            };
          });
          localStorage.setItem('teams', JSON.stringify(updatedTeamsInStorage));

          // Set enabledTeams from search params
          let teamsFromParams = teamsInStorage.filter((team) => teamIDsFromParams.includes(team.id));
          setEnabledTeams(teamsFromParams);
        }
        // Handle tableState
        else {
          // Parse all values except globalFilter
          const parsedValue = key === 'globalFilter' ? value : JSON.parse(value);
          newTableState[key] = parsedValue;
        }
      } catch (error) {
        console.error(`Failed to parse ${key}:`, error);
        if (key !== 'selectedView' && key in newTableState) {
          newTableState[key] = value; // Assign raw value if JSON parsing fails
        }
      }
    });

    // Update dueDate columnFilter so that dates are either undefined or use dayjs
    let dueDateIndex = newTableState.columnFilters?.findIndex((filter) => filter.id === 'dueDate');
    if (dueDateIndex >= 0) {
      // Update date to a dayjs object, or to undefined if it's falsy
      newTableState.columnFilters[dueDateIndex].value = newTableState.columnFilters[dueDateIndex].value.map((date) =>
        !date ? (date = undefined) : (date = dayjs(date)),
      );
    }

    // Apply the new state if any search parameters are found
    if (deepEqual(newTableState, tableState)) {
      setTableState(tableState);
    } else {
      setTableState(newTableState);
    }
    isInitialLoad.current = true; // Set this after initial load
  }, []);

  const clearSelectedView = () => {
    setSelectedView(0);
  };

  // TODO: fix sendPostRequest and sendGetRequest to actually return promises so we can use isFetching here
  const { data, isPlaceholderData, isFetching, isRefetching, isError, error, refetch } = useQuery({
    queryKey: ['allTasks'],
    queryFn: () => getAllTasks(),
    select: (data) => flattenTaskData(data, tableState.groupCRandMRs),
    placeholderData: [],
    refetchInterval: 60 * 1000,
  });

  const queryClient = useQueryClient();

  const saveView = useMutation({
    mutationFn: async () => {
      const savedViewState = { ...tableState };
      (savedViewState.pagination.pageIndex = 0), delete savedViewState.rowSelection;
      Object.entries(savedViewState).forEach(([key, value]) => {
        if (deepEqual(value, defaultTableState[key])) {
          delete savedViewState[key];
        }
      });
      return await sendPostRequest('/save-table-view', { userQawId, name: draftName, state: savedViewState, tableName: 'taskTable' });
    },
    onError: (error) => {
      setToast(
        <Toast
          title={'Saving View Failed'}
          message={error.message || 'An error occurred while saving the view. Please try again.'}
          key={new Date().toISOString()}
          isSuccess={false}
        />,
      );
    },
    onSuccess: ({ data }) => {
      queryClient.refetchQueries({
        queryKey: ['savedTableViews'],
      });
      setSelectedView(data.id);
      setDraftName(''); // Clear the draft name
    },
  });

  const deleteView = useMutation({
    mutationFn: async (viewId) => {
      return await sendPostRequest('/delete-table-view', { viewId });
    },
    onError: (error) => {
      setToast(
        <Toast
          title={'Saving View Failed'}
          message={error.message || 'An error occurred while saving the view. Please try again.'}
          key={new Date().toISOString()}
          isSuccess={false}
        />,
      );
    },
    onSuccess: ({ viewId }) => {
      queryClient.refetchQueries({
        queryKey: ['savedTableViews'],
      });
      if (selectedView === viewId) {
        setSelectedView(0);
      }
    },
  });

  const saveViewButton = (
    <Button onClick={() => setOpenViewNameModal(true)} variant="contained">
      Save View
    </Button>
  );

  if (isError) return <span>Error: {error.message}</span>;

  return (
    <>
      <TeamSelector enabledTeams={enabledTeams} setEnabledTeams={setEnabledTeams} refetchQuery={refetch} />
      <SavedViews
        selectedView={selectedView}
        setSelectedView={setSelectedView}
        setTableState={setTableState}
        deleteView={deleteView}
        setToast={setToast}
        defaultTableState={defaultTableState}
      />
      <TasksTable
        data={data}
        initialInstanceState={{}}
        setToast={setToast}
        showSkeletons={isPlaceholderData && isFetching}
        showProgressBars={!isPlaceholderData && isRefetching}
        enableSavingViews={true}
        tableState={tableState}
        setTableState={setTableState}
        saveViewButton={saveViewButton}
        clearSelectedView={clearSelectedView}
        selectedView={selectedView}
        defaultTableState={defaultTableState}
        queryClient={queryClient}
      />
      <SaveViewModal open={openViewNameModal} setOpen={setOpenViewNameModal} name={draftName} setName={setDraftName} mutateTableView={saveView} />
      {toast}
    </>
  );
}
