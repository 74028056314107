import { Box, Typography, Paper } from '@mui/material';
import dayjs from 'dayjs';
import { getSubmilestoneStyles } from './utils';
import { MilestoneProgress } from '../types';

export default function SubMilestone({
  subMilestone,
  requiredVelocity,
  thisWeeksTestCount,
}: {
  subMilestone: MilestoneProgress;
  requiredVelocity: number;
  thisWeeksTestCount: number;
}) {
  const { rowBgColor, testsBgColor, weekFontWt, weekFontClr } = getSubmilestoneStyles(subMilestone, requiredVelocity, thisWeeksTestCount);
  const weekStr = dayjs(subMilestone.dueDate).startOf('week').format('M/D');
  const isCurrWeek = dayjs(subMilestone.dueDate).isSame(dayjs(), 'week');
  const isFutureWeek = dayjs(subMilestone.dueDate).isAfter(dayjs(), 'week');
  const hasMetGoal = requiredVelocity <= 0;

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'row',
        width: '100%',
        p: '.5em',
        bgcolor: rowBgColor,
        borderRadius: '5px',
        justifyContent: 'space-evenly',
      }}
    >
      <Box sx={{ width: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'space-evenly' }}>
        <Typography lineHeight={1.1} fontWeight={weekFontWt} color={weekFontClr}>
          {`Week ${subMilestone.weekNumber}`}
        </Typography>
        <Typography variant="caption" lineHeight={1} color={weekFontClr}>
          {`${weekStr} - ${dayjs(subMilestone.dueDate).endOf('week').format('M/D')}`}
        </Typography>
      </Box>
      <Box sx={{ width: '100%', justifyContent: 'center', display: 'flex', alignItems: 'center' }}>
        <Typography>{!isFutureWeek && !isCurrWeek && thisWeeksTestCount}</Typography>
      </Box>
      <Box sx={{ width: '100%', display: 'flex', justifyContent: 'right' }}>
        <Paper
          sx={{
            bgcolor: testsBgColor,
            height: '100%',
            width: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            borderRadius: '5px',
          }}
        >
          <Typography color="white" fontSize={14} fontWeight="bold">
            {!isCurrWeek && !isFutureWeek
              ? `${subMilestone.actualTests} / ${subMilestone.targetTests}`
              : hasMetGoal
              ? `0 Tests`
              : isCurrWeek
              ? `${thisWeeksTestCount} / ${requiredVelocity} Created`
              : `Target: ${requiredVelocity} Tests`}
          </Typography>
        </Paper>
      </Box>
    </Box>
  );
}
