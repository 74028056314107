import { Menu, Transition } from '@headlessui/react';
import {
  ClockIcon,
  EllipsisHorizontalIcon,
  InboxArrowDownIcon,
  TrashIcon,
  ClipboardDocumentCheckIcon,
  CheckCircleIcon,
} from '@heroicons/react/24/outline';

import { parseISO } from 'date-fns';
import { Fragment } from 'react';
import { getTeamInfo } from '../../../utils/helpers';
import { classNames, getBackgroundColor, getElapsed } from '../helpers';
import { useMutationState } from '@tanstack/react-query';
import { useDisabledTasks } from '../../../hooks/useDisabledTasks';

const teamLeadsFromCache = getTeamInfo();

export function GenericTaskCard({ task, toggleClaimed, user, suiteAction }) {
  const { disabledTasks, setDisabledTasks } = useDisabledTasks();

  const isProjectPlanTask = task.name.includes('Update scheduled tasks for this and next week');
  const title = `${task.team.name} - ${isProjectPlanTask ? 'Approve Project Plan' : 'Generic Task'}`;
  const thisTaskAge = getElapsed(parseISO(task?.createdAt));
  const claimedByAnyone = task.assignedTo?.name ? true : false;
  const claimedByCurrentUser = task.assignedTo?.id === user.qawId;

  // enrich the task with extra info
  task.qaTeam = teamLeadsFromCache.filter((x) => x.qawId === task.qaLead.id)[0] || {
    imageUrl: '',
    teamName: 'Unknown',
    name: 'Unknown',
  };

  // get the message and url if task was created from a message
  if (task.notesPlainText) {
    const { url, message } = JSON.parse(task.notesPlainText);
    task.messageUrl = url;
    task.message = message;
  }

  const startButton = (
    <button
      className="rounded-md py-1 px-2 text-xs font-medium ring-1 ring-inset text-blue-700 bg-blue-50 ring-blue-600/20 hover:ring-2 hover:bg-blue-100"
      onClick={() => {
        setDisabledTasks((prev) => ({ ...prev, [`${task.id}-inProgress`]: true }));
        suiteAction(task.id, '/claim'); // Reusing /claim logic to move task to inProgress status
      }}
      disabled={disabledTasks[`${task.id}-inProgress`]}
    >
      {disabledTasks[`${task.id}-inProgress`] ? 'Pending...' : 'Start'}
    </button>
  );

  return (
    <div className="rounded-xl border border-gray-200 bg-white">
      <div className={classNames('rounded-t-xl flex items-center gap-x-4 border-b border-gray-900/5 p-4', getBackgroundColor(task))}>
        <div className="w-full grid grid-cols-5 ">
          {/* Team image & Client name  */}
          <div className="overflow-hidden flex justify-start text-sm font-medium col-span-2">
            <img
              className="h-6 w-6 rounded-full mr-2"
              src={task.qaTeam.imageUrl}
              alt={`${task.qaTeam.teamName} Team - ${task.qaTeam.name}`}
              title={`${task.qaTeam.teamName} Team - ${task.qaTeam.name}`}
            />
            <p className="truncate flex ext-gray-900 max-w-2/5" title={`${title} | ID ${task.id}`}>
              {title}
            </p>
          </div>
          <div className="flex justify-end col-span-3 xl:gap-1 2xl:gap-2">
            {!claimedByAnyone ? (
              <>
                {/* Only buttons if unclaimed */}
                <DoneButton suiteAction={suiteAction} task={task} isProjectPlanTask={isProjectPlanTask} />
                <button
                  className="rounded-md py-1 px-2 text-xs font-medium ring-1 ring-inset text-blue-700 bg-blue-50 ring-blue-600/20 hover:ring-2 hover:bg-blue-100"
                  onClick={() => toggleClaimed(task.id, true)}
                >
                  Claim
                </button>
              </>
            ) : (
              <>
                {/* Assigned user deatils if claimed */}
                <div className="flex justify-right xl:gap-1 2xl:gap-2">
                  <img className="h-6 w-6 rounded-full" src={task.assignedTo?.avatarUrl} alt={task.assignedTo?.name} title={task.assignedTo?.name} />
                  {/* Only show the user's name if it's not the current user to save space */}
                  {claimedByCurrentUser || <h3 className={classNames('truncate text-sm', 'font-medium text-gray-900')}>{task.assignedTo?.name}</h3>}
                </div>
                {/* Start button if claimed by current user and still in To Do */}
                {claimedByCurrentUser && task.status === 'toDo' && <>{startButton}</>}
                {/* Done buttons if claimed by current user */}
                {claimedByCurrentUser && <DoneButton suiteAction={suiteAction} task={task} isProjectPlanTask={isProjectPlanTask} />}
              </>
            )}

            {/* 'See' button always visible */}
            {isProjectPlanTask ? (
              <a
                href={`/teamTasks/${task.team.id}`}
                target="_blank"
                rel="noreferrer"
                className="rounded-md py-1 px-2 text-xs font-medium ring-1 ring-inset bg-gray-50 text-gray-700 ring-gray-600/20 hover:ring-2 hover:bg-gray-100"
              >
                See Customer Tasks
              </a>
            ) : (
              task.messageUrl && (
                <a
                  href={task.messageUrl}
                  target="_blank"
                  rel="noreferrer"
                  className="rounded-md py-1 px-2 text-xs font-medium ring-1 ring-inset bg-gray-50 text-gray-700 ring-gray-600/20 hover:ring-2 hover:bg-gray-100"
                >
                  {task.messageUrl.includes('teams')
                    ? 'See MS Teams Message'
                    : task.messageUrl.includes('discord')
                      ? 'See Discord Message'
                      : 'See Slack Message'}
                </a>
              )
            )}

            {/* Action Menu */}
            <Menu
              as="div"
              className="relative rounded-md py-1 px-2 text-xs font-medium ring-1 ring-inset bg-gray-50 text-gray-700 ring-gray-600/20 hover:ring-2 hover:bg-gray-100"
            >
              <Menu.Button className="-m-2.5 block p-2.5 text-gray-400 hover:text-gray-500">
                <EllipsisHorizontalIcon className="h-4 w-4" aria-hidden="true" />
              </Menu.Button>
              <Transition
                as={Fragment}
                enter="transition ease-out duration-100"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95"
              >
                <Menu.Items className="absolute right-0 z-10 mt-0.5 w-24 origin-top-right rounded-md bg-white py-2 shadow-lg ring-1 ring-gray-900/5 focus:outline-none">
                  {claimedByAnyone &&
                    (claimedByCurrentUser ? (
                      <Menu.Item>
                        {({ active }) => (
                          <button
                            type="button"
                            className={classNames(active ? 'bg-gray-100' : '', 'w-full flex px-3 py-1 text-sm leading-6 text-yellow-900')}
                            onClick={() => toggleClaimed(task.id, false)}
                          >
                            <InboxArrowDownIcon className="h-5 w-5 pt-1 mr-1" /> Unclaim
                          </button>
                        )}
                      </Menu.Item>
                    ) : (
                      <Menu.Item>
                        {({ active }) => (
                          <button
                            type="button"
                            className={classNames(active ? 'bg-gray-100' : '', 'w-full flex px-3 py-1 text-sm leading-6 text-yellow-900')}
                            onClick={() => toggleClaimed(task.id, true)}
                          >
                            <InboxArrowDownIcon className="h-5 w-5 pt-1 mr-1" /> Claim
                          </button>
                        )}
                      </Menu.Item>
                    ))}
                  <Menu.Item>
                    {({ active }) => (
                      <button
                        type="button"
                        className={classNames(active ? 'bg-gray-100' : '', 'w-full flex px-3 py-1 text-sm leading-6 text-red-600')}
                        onClick={() => suiteAction(task.id, '/ignore')}
                      >
                        <TrashIcon className="h-5 w-5 pt-1 mr-1" /> Delete
                      </button>
                    )}
                  </Menu.Item>
                </Menu.Items>
              </Transition>
            </Menu>
          </div>
        </div>
      </div>
      <dl className="-my-3 divide-y divide-gray-100 p-4 text-sm leading-6">
        <div className="py-2">
          <dt className="flex justify-between gap-x-3 text-gray-500 -ml-1">
            <div className="flex w-1/2">
              <div className="w-1/10">
                {isProjectPlanTask ? (
                  <ClipboardDocumentCheckIcon className="mt-0.5 w-5 h-5 text-red-500" />
                ) : (
                  <CheckCircleIcon className="mt-0.5 w-5 h-5 text-red-500" />
                )}
              </div>
              <p className="truncate ml-2 text-gray-500" title={task.name}>
                {task.name}
              </p>
            </div>
            <div className={classNames('flex', 'text-gray-500')}>
              <ClockIcon className={classNames('w-5 h-5  mt-0.5 mr-1', 'text-gray-500')} title={`Task created: ${thisTaskAge}`} />
              <p title={`Task created: ${thisTaskAge}`}>{thisTaskAge}</p>
            </div>
          </dt>
        </div>
      </dl>
    </div>
  );
}

function DoneButton({ suiteAction, task, isProjectPlanTask }) {
  const mutations = useMutationState({
    filters: { mutationKey: ['taskMutation'] },
    select: (mutation) => mutation.state,
  });

  const isPending = mutations.find((x) => x.variables?.taskId === task.id && x.status === 'pending' && x.variables?.endpoint === '/done');
  const isSuccess = mutations.find((x) => x.variables?.taskId === task.id && x.status === 'success' && x.variables?.endpoint === '/done');
  return (
    <button
      className="rounded-md py-1 px-2 text-xs font-medium ring-1 ring-inset text-green-700 bg-green-50 ring-green-600/20 hover:ring-2 hover:bg-green-100"
      onClick={() => suiteAction(task.id, '/done')}
      disabled={isPending || isSuccess}
    >
      {isPending ? 'Pending...' : isSuccess ? 'Success!' : isProjectPlanTask ? 'Approve' : 'Done'}
    </button>
  );
}
