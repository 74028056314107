function facebookAuth() {
  const userId = localStorage.getItem('userId');
  const rootUrl = `https://www.facebook.com/v20.0/dialog/oauth`;

  const options = {
    redirect_uri: window.location.origin + '/facebook/code',
    client_id: '1739579036856839',
    scope: 'public_profile',
    response_type: 'code',
    state: userId || '',
  };

  const qs = new URLSearchParams(options);
  window.location.replace(`${rootUrl}?${qs.toString()}`);
}

/**
 *
 * @returns {import('react').JSX.Element}
 */
export default function FacebookConnector() {
  return (
    <button
      type="button"
      className="rounded-md bg-indigo-500 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-500"
      onClick={() => facebookAuth()}
    >
      Connect to Facebook
    </button>
  );
}
