import {
  Modal,
  Paper,
  TextField,
  Button,
  Typography,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Box,
  Chip,
  Autocomplete,
  Checkbox,
  FormControlLabel,
} from '@mui/material';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useEffect, useState } from 'react';
import { sendPostRequest, sendPutRequest } from '../../utils/network';
import Toast from '../Layout/Toast';
import WolfLoader from '../WolfLoader/WolfLoader';

function BlockedReasonModal({ showBlockedReasonModal, setShowBlockedReasonModal, originalBlockedTask, setOriginalBlockedTask, setToast }) {
  // State for error handling
  const [titleError, setTitleError] = useState(false);
  const [reasonError, setReasonError] = useState(false);
  const [followUpError, setFollowUpError] = useState(false);

  // State for mutation/API request data
  const [blockerTitle, setBlockerTitle] = useState('');
  const [blockerReason, setBlockerReason] = useState('');
  const [followUpDate, setFollowUpDate] = useState('');
  const [selectedTaskIds, setSelectedTaskIds] = useState([]);
  const [selectedExistingBlocker, setSelectedExistingBlocker] = useState(null);

  // State for disabling name input when the CR is fully blocked (all tasks are selected)
  const [isFullyBlocked, setIsFullyBlocked] = useState(false);
  const [splitCr, setSplitCr] = useState(false);

  // Resets all state vars
  const resetState = () => {
    // Reset error state
    setTitleError(false);
    setReasonError(false);
    setFollowUpError(false);

    // Reset data state
    setBlockerTitle('');
    setBlockerReason('');
    setFollowUpDate('');
    setBlockerTitle('');
    setOriginalBlockedTask(null);
    setSelectedTaskIds([]);
    setSelectedExistingBlocker(null);
    setIsFullyBlocked(false);
    setSplitCr(false);

    // Reset show modal state
    setShowBlockedReasonModal(false);
  };

  useEffect(() => {
    if (originalBlockedTask && originalBlockedTask.type === 'testCreation') {
      setSelectedTaskIds([originalBlockedTask.id]);
    }

    // Handle setting outline task to blocked
    if (originalBlockedTask && originalBlockedTask.type === 'outline') {
      // If the blocked task is an outline, it should be fully blocked
      setIsFullyBlocked(true);
    }

    // Handle CRs with 1 test creation task (should be fully blocked)
    if (originalBlockedTask && originalBlockedTask.type === 'testCreation' && crWorkflowTasks.length === 1) {
      setIsFullyBlocked(true);
    }
  }, [originalBlockedTask]);

  // CR workflow tasks that could be added to the blocked CR
  const crWorkflowTasks =
    originalBlockedTask?.parentTask?.childTasks?.filter(
      (t) => t.type === 'testCreation' && (t.id === originalBlockedTask.id || t.status !== 'done'),
    ) || [];

  // Get queryClient to mutate cached task data
  const queryClient = useQueryClient();

  // Get existing customer blockers
  /**@type {import('../../types').QAWTask[]} */
  const allTasks = queryClient.getQueryData(['allTasks', originalBlockedTask?.team?.id]) || queryClient.getQueryData(['allTasks']) || [];
  const customerTasks = allTasks.filter((t) => t.team.id === originalBlockedTask?.team?.id);
  const customerBlockers = [];
  const blockerIds = new Set();
  for (const task of customerTasks) {
    if (task.blocker && !blockerIds.has(task.blocker.id)) {
      customerBlockers.push(task.blocker);
      blockerIds.add(task.blocker.id);
    }
    for (const childTask of task.childTasks || []) {
      if (childTask.blocker && !blockerIds.has(childTask.blocker.id)) {
        customerBlockers.push(childTask.blocker);
        blockerIds.add(childTask.blocker.id);
      }
    }
  }

  // Mutation to update the blocked workflow tasks/create blocked CR
  const createCrMutation = useMutation({
    /**
     * @param {import('../../types').blockedWorkflowData} blockedWorkflowData
     * @returns {Promise}
     */
    mutationFn: async (blockedWorkflowData) => await sendPostRequest(`/set-workflows-to-blocked`, blockedWorkflowData),
    onSettled: (data) => {
      queryClient.refetchQueries({ queryKey: ['allTasks'] }).finally(() => {
        resetState();
        if (data.success === true) {
          return setToast(
            <Toast
              title={'Blocked CR created'}
              message={
                isFullyBlocked || !splitCr
                  ? 'Task statuses have been updated successfully'
                  : 'The blocked CR has been created and the selected workflows have been moved to the new CR'
              }
              key={new Date().toISOString()}
              isSuccess={true}
            />,
          );
        } else {
          console.log('Task update failed:', data.error);
          return setToast(
            <Toast
              title={'Updating tasks failed'}
              message={'Sorry, there was an problem! The Dragons team has been notified'}
              key={new Date().toISOString()}
              isSuccess={false}
            />,
          );
        }
      });
    },
  });

  // Mutation to add workflows to an existing blocked CR
  const updateCrMutation = useMutation({
    /**
     * @param {object} updateBlockerData
     * @param {import('../../types').existingBlocker} updateBlockerData.existingBlocker
     * @param {import('../../types').QAWTask[]} updateBlockerData.blockedWorkflowTasks
     * @param {import('../../types').QAWTask} updateBlockerData.parentCrTask
     * @returns {Promise}
     */
    mutationFn: async (updateBlockerData) => await sendPutRequest(`/add-workflows-to-blocked-cr`, updateBlockerData),
    onSettled: (data) => {
      queryClient.refetchQueries({ queryKey: ['allTasks'] }).finally(() => {
        resetState();
        if (data.success === true) {
          return setToast(
            <Toast
              title={'Blocked CR created'}
              message={'Tasks have been moved to the selected blocked CR'}
              key={new Date().toISOString()}
              isSuccess={true}
            />,
          );
        } else {
          console.log('Task update failed:', data.error);
          return setToast(
            <Toast
              title={'Updating tasks failed'}
              message={'Sorry, there was an problem! The Dragons team has been notified'}
              key={new Date().toISOString()}
              isSuccess={false}
            />,
          );
        }
      });
    },
  });

  const handleSelectBlockedTask = (e) => {
    const filteredSelectValues = e.target.value.filter((taskId) => taskId !== 'select-all-tasks');
    const hasSelectedAllTasks = filteredSelectValues.length === crWorkflowTasks.length;

    // Handle select all
    if (e.target.value.includes('select-all-tasks') && !hasSelectedAllTasks) {
      setTitleError(false); // Title is not needed if CR is fully blocked
      setIsFullyBlocked(true);
      setSelectedExistingBlocker(null);
      return setSelectedTaskIds(crWorkflowTasks.map((t) => t.id));
    }

    // Handle unselect all
    if (e.target.value.includes('select-all-tasks') && hasSelectedAllTasks) {
      setIsFullyBlocked(false);
      return setSelectedTaskIds([originalBlockedTask.id]);
    }

    // Handle individual selection/deselection
    if (hasSelectedAllTasks) {
      setTitleError(false); // Title is not needed if CR is fully blocked
      setSelectedExistingBlocker(null);
    }
    setIsFullyBlocked(hasSelectedAllTasks);
    setSplitCr(false);
    return setSelectedTaskIds(filteredSelectValues);
  };

  const handleClose = () => {
    // Reset the blocked task status back to it's original value
    queryClient.setQueryData(
      ['allTasks'],
      /**
       * @param {import('../../types').QAWTask[]} oldData
       * @returns {import('../../types').QAWTask[]} - Updated customer task data
       */
      (oldData) => {
        if (!oldData) return oldData;
        const remappedData = oldData.map((task) => {
          // Find the parent task
          if (task.id === originalBlockedTask.parentTask.id && task.childTasks) {
            // Find the child task and reset its status
            const newChildTasks = task.childTasks.map((childTask) => {
              if (childTask.id === originalBlockedTask.id) {
                return { ...childTask, status: originalBlockedTask.status };
              }
              return childTask;
            });

            return { ...task, status: originalBlockedTask.status, childTasks: newChildTasks };
          }
          return task;
        });
        return remappedData;
      },
    );

    // Reset the data and modal state
    return resetState();
  };

  const handleSubmit = () => {
    // Handle creating a new blocked CR or updating the original if fully blocked
    if (!selectedExistingBlocker) {
      // Validate the form data
      if ((!blockerTitle.length && !isFullyBlocked) || !blockerReason || !followUpDate) {
        setTitleError(!blockerTitle.length && !isFullyBlocked);
        setReasonError(!blockerReason.length);
        setFollowUpError(!followUpDate.length);
        return;
      }

      return createCrMutation.mutate({
        blockedWorkflowTasks: crWorkflowTasks.filter((t) => selectedTaskIds.includes(t.id)),
        teamName: originalBlockedTask.parentTask.team.name,
        blockerTitle: isFullyBlocked ? originalBlockedTask.parentTask.issue?.name || originalBlockedTask.parentIssue.name : blockerTitle,
        blockerReason,
        followUpDate,
        parentCrTask: originalBlockedTask.parentTask,
        isOutlineTask: originalBlockedTask.type === 'outline',
        splitCr,
      });
    }

    // Handle adding tasks to an existing blocked CR or the original
    return updateCrMutation.mutate({
      existingBlocker: selectedExistingBlocker,
      blockedWorkflowTasks: crWorkflowTasks.filter((t) => selectedTaskIds.includes(t.id)),
      parentCrTask: originalBlockedTask.parentTask,
    });
  };

  // Show loading modal while the API request is being made
  const loadingMessage =
    createCrMutation.isPending && (isFullyBlocked || !splitCr)
      ? 'Updating CR...'
      : createCrMutation.isPending
        ? 'Creating Blocked CR...'
        : updateCrMutation.isPending
          ? 'Adding tasks to existing blocked CR...'
          : 'Fetching existing blockers...';
  const isLoading = createCrMutation.isPending || updateCrMutation.isPending;

  return isLoading ? (
    <LoadingModal open={true} handleClose={handleClose} message={loadingMessage} />
  ) : (
    <Modal open={showBlockedReasonModal} onClose={handleClose} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
      <Paper
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          p: 4,
          width: '50%',
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <Typography variant="h6" sx={{ mt: -1 }}>
          {originalBlockedTask?.type !== 'outline' ? `Create New Blocker` : `Block this CR/Outline`}
        </Typography>
        <Typography variant="caption">
          {originalBlockedTask?.type !== 'outline'
            ? `
          Create a blocker for the selected tasks. If the "Split CR" option is selected, a new blocked CR will be created, and any unselected tasks will remain in the original CR.
          `
            : `Please provide additional blocker details before setting this CR to blocked.`}
        </Typography>
        {originalBlockedTask?.type !== 'outline' && (
          <Autocomplete
            freeSolo
            options={customerBlockers.map((b) => ({ ...b, label: b.title }))}
            disabled={isFullyBlocked}
            value={isFullyBlocked ? '' : selectedExistingBlocker}
            onChange={(_event, newValue) => {
              setSelectedExistingBlocker(newValue);
              setTitleError(false);
              setReasonError(false);
              setFollowUpError(false);
            }}
            isOptionEqualToValue={(option, value) => option.id === value.id}
            renderOption={(props, option) => (
              <li {...props} key={`${option.label}-${option.id}`}>
                {option.label}
              </li>
            )}
            renderInput={(params) => (
              <TextField
                {...params}
                value={blockerTitle}
                label="Create blocker title or Select existing blocker"
                placeholder={isFullyBlocked ? originalBlockedTask.parentTask.issue.name : 'e.g. "Missing user data"'}
                fullWidth
                required
                error={titleError}
                onChange={(e) => {
                  setTitleError(false);
                  setBlockerTitle(e.target.value);
                  setSelectedExistingBlocker(null);
                }}
                InputLabelProps={{ shrink: true }}
                sx={{ mt: 2 }}
              />
            )}
          />
        )}
        <TextField
          label="Detailed reason for blocked status"
          multiline
          fullWidth
          required
          disabled={selectedExistingBlocker !== null}
          error={reasonError}
          rows={2}
          value={!selectedExistingBlocker ? blockerReason : selectedExistingBlocker.description}
          onChange={(e) => {
            setReasonError(false);
            setBlockerReason(e.target.value);
          }}
          sx={{ mt: 2 }}
          InputLabelProps={{ shrink: true }}
        />
        <TextField
          label="Follow-up Date"
          type="date"
          fullWidth
          required
          disabled={selectedExistingBlocker !== null}
          error={followUpError}
          value={!selectedExistingBlocker ? followUpDate : ''}
          onChange={(e) => {
            setFollowUpError(false);
            setFollowUpDate(e.target.value);
          }}
          sx={{ mt: 2 }}
          InputLabelProps={{ shrink: true }}
        />
        {crWorkflowTasks.length > 0 && originalBlockedTask.type !== 'outline' && (
          <FormControl sx={{ mt: 2 }}>
            <InputLabel id="additional-blocked-workflow-tasks-label" disableAnimation shrink sx={{ backgroundColor: '#fff', ml: -1, pl: 1, pr: 1 }}>
              Select workflow creation tasks affected by this blocker
            </InputLabel>
            <Select
              multiple
              labelId="additional-blocked-workflow-tasks-label"
              value={selectedTaskIds}
              onChange={handleSelectBlockedTask}
              renderValue={(selected) => (
                <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                  {selected.map((taskId) => (
                    <Chip key={taskId} label={crWorkflowTasks.find((t) => t.id === taskId).workflow.name} />
                  ))}
                </Box>
              )}
            >
              <MenuItem value="select-all-tasks">
                <Checkbox checked={selectedTaskIds.length === crWorkflowTasks.length} />
                {isFullyBlocked ? 'Unselect all' : 'Select all'}
              </MenuItem>
              {crWorkflowTasks.map((task) => {
                return (
                  <MenuItem key={task.id} value={task.id} disabled={task.id === originalBlockedTask.id}>
                    <Checkbox checked={selectedTaskIds.includes(task.id)} />
                    {task.workflow.name}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
        )}
        <Button variant="contained" color="primary" onClick={handleSubmit} sx={{ mt: 2 }}>
          {isFullyBlocked
            ? 'Update this CR'
            : selectedExistingBlocker
              ? 'Add selected tasks to existing blocked CR'
              : !splitCr
                ? 'Update status for selected tasks'
                : 'Create new blocked CR'}
        </Button>
        {!isFullyBlocked && !selectedExistingBlocker && (
          <FormControlLabel control={<Checkbox checked={splitCr} onChange={() => setSplitCr((prev) => !prev)} />} label="Split CR" />
        )}
      </Paper>
    </Modal>
  );
}

function LoadingModal({ open, handleClose, message }) {
  return (
    <Modal open={open} onClose={handleClose} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
      <Paper
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          p: 4,
          width: '50%',
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <Typography variant="h6" sx={{ mt: -1 }}>
          {message}
        </Typography>
        <WolfLoader customStyles={{ height: '100%' }} />
      </Paper>
    </Modal>
  );
}

export default BlockedReasonModal;
