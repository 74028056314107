import { Box, Typography } from '@mui/material';
import WolfLoader from '../../WolfLoader/WolfLoader';
import { useQuery } from '@tanstack/react-query';
import { sendGetRequest } from '../../../utils/network';
import { useEffect, useState } from 'react';
import ProgressDateRange from './ProgressDateRange';
import dayjs from 'dayjs';
import ClientMetricsTable from './ClientMetricsTable';

export default function CreationAndMaintenanceProgress({ team }) {
    const [startDate, setStartDate] = useState(dayjs().subtract(8, 'week').startOf('week')); // Default to 8 weeks ago
    const [endDate, setEndDate] = useState(dayjs()); // Default to today

    // Get additional client summary data
    const { data, isFetching, isLoading, isError, error, refetch } = useQuery({
        queryKey: ['clientSummary'],
        queryFn: () => sendGetRequest(`/client-summaries/${team.id}?startDate=${dayjs(startDate)}&endDate=${dayjs(endDate)}`),
        select: ({ data }) => data,
    });

    useEffect(() => {
        refetch();
    }, [startDate, endDate]);

    // Function to update the date range
    const handleDateChange = (date, type) => {
        if (type === 'start') {
            // Ensure start date is before end date
            if (date.isAfter(endDate)) setEndDate(date);
            setStartDate(date);
        } else {
            // Ensure end date is after start date
            if (date.isBefore(startDate)) setStartDate(date);
            setEndDate(date);
        }
    };

    // Show load spinner on initial load
    if (isFetching && isLoading)
        return (
            <Box>
                <WolfLoader customStyles={{ height: '100%' }} />
            </Box>
        );
    if (isError) return <Typography>{error.message}</Typography>;

    return (
        <Box>
            <Box className="flex justify-start items-center mt-4">
                <Typography variant="h6">Creation and Maintenance Progress</Typography>
                <ProgressDateRange handleDateChange={handleDateChange} startDate={startDate} endDate={endDate} />
            </Box>
            <Box>
                <ClientMetricsTable data={data} startDate={startDate} endDate={endDate} team={team} />
            </Box>
        </Box>
    );
}
