import { useMemo, useState } from 'react';
import dayjs from 'dayjs';

//MRT Imports
import { MaterialReactTable, useMaterialReactTable, MRT_GlobalFilterTextField, MRT_ShowHideColumnsButton } from 'material-react-table';

//Material UI Imports
import { Box, Button, Dialog, DialogContent, DialogTitle, ListItemIcon, MenuItem, Typography, lighten } from '@mui/material';

//Icons Imports
import { AccountCircle } from '@mui/icons-material';
import ArchiveIcon from '@mui/icons-material/Archive'; // for some reason, importing from above errors

//Date Picker Imports - these should just be in your Context Provider
import { initCandidateFinalRound, archiveSubmissions } from './api';
import { handleSubmissionChange } from './helpers/hiringHelpers';
import { statusMap } from './helpers/constants';


import { useQueryClient } from '@tanstack/react-query';
import RowDetailPanel from './HiringDetailPanel';
import { CircularProgress } from '@mui/material';
import Toast from '../Layout/Toast';

const HiringTable = ({ data }) => {
  const [open, setOpen] = useState(false);
  const [selectedFinalRoundRowData, setSelectedFinalRoundRowData] = useState({});
  const [isLoadingSuperday, setIsLoadingSuperday] = useState(false);
  const [toast, setToast] = useState(<></>);

  const queryClient = useQueryClient();

  const [value, setValue] = useState(() =>
    data.reduce((acc, row) => {
      acc[row.id] = row.status || '';
      return acc;
    }, {}),
  );


  const handleInitFinalRound = async (superday) => {
    setIsLoadingSuperday(true);

    const candidateData = {
      superday,
      candidateEmail: selectedFinalRoundRowData.original.candidate.email,
      candidateName: selectedFinalRoundRowData.original.candidate.firstName,
      submissionId: selectedFinalRoundRowData.original.id,
    };


    let result = await initCandidateFinalRound(candidateData);
    let { success } = result;

    if (!success) {
      console.log(result);
    }

    let title = success ? 'Success!' : 'Ruh-roh';
    let message = success ? 'Candidate superday created' : 'Something went wrong';

    setIsLoadingSuperday(false);
    setOpen(false);
    setToast(<Toast title={title} message={message} key={new Date().toISOString()} isSuccess={success} />);
  };

  const handleArchiveSubmissions = async (rows, table) => {
    let submissionsToBeArchived = rows.map((row) => row.original.id);

    let result = await archiveSubmissions({ ids: submissionsToBeArchived });

    let { success } = result;

    if (!success) {
      console.log(result);
    }

    let title = success ? 'Success!' : 'Ruh-roh';
    let message = success
      ? `Submission(s) archived.`
      : 'Something went wrong! Unable to archive submission(s).';

    setToast(<Toast title={title} message={message} isSuccess={success} />);

    await queryClient.refetchQueries({ queryKey: ['combinedData'] });
    await table.resetRowSelection();
  };

  const columns = useMemo(
    () => [
      {
        id: 'candidates', //id used to define `group` column
        header: 'Candidates',

        columns: [
          // ============================ CANDIDATE NAME COLUMN ============================
          {
            accessorFn: (row) => `${row.candidate.firstName} ${row.candidate.lastName}`, //accessorFn used to join multiple data into a single cell
            id: 'name', //id is still required when using accessorFn instead of accessorKey
            header: 'Candidate Name',
            Cell: ({ renderedCellValue, row }) => {

              const hasNotification = row.original.removedFromSandbox;

              if (!hasNotification) return (
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                  }}
                >
                  <span>{renderedCellValue}</span>
                </Box>);

              return (
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                  }}
                >
                  <span>{renderedCellValue}</span>
                  <div className="w-2.5 h-2.5 bg-red-500 rounded-full ml-2"></div>
                </Box>

              );
            },
          },
          // ============================ CANDIDATE EMAIL COLUMN ============================
          {
            accessorFn: (row) => row?.candidate.email, //accessorFn used to join multiple data into a single cell

            id: 'email', //id is still required when using accessorFn instead of accessorKey
            header: 'Candidate Email',
            size: 100,
            Cell: ({ renderedCellValue }) => (
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                <span>{renderedCellValue}</span>
              </Box>
            ),
          },
          // ============================ JOB SOURCE COLUMN ============================
          {
            accessorFn: (row) => row?.jobSourceName, //accessorFn used to join multiple data into a single cell

            id: 'jobSource', //id is still required when using accessorFn instead of accessorKey
            header: 'Job Source',
            size: 100,
            Cell: ({ renderedCellValue }) => (
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                <span>{renderedCellValue}</span>
              </Box>
            ),
          },
          // ============================ DATE SUBMITTED COLUMN ============================
          {
            accessorFn: (row) => (row.dateSubmitted ? dayjs(row.dateSubmitted) : null), //accessorFn used to join multiple data into a single cell

            id: 'dateSubmitted', //id is still required when using accessorFn instead of accessorKey
            header: 'Date Submitted',
            filterVariant: 'date-range',
            size: 100,
            Cell: ({ cell }) => {
              return <div>{cell.getValue() ? (cell.getValue()).format('MM/DD/YYYY') : ''}</div>;
            },
          },
          // ============================ CANDIDATE LOCATION COLUMN ============================
          {
            accessorFn: (row) => ({
              timezone: row.candidate.location,
            }), //accessorFn used to join multiple data into a single cell

            id: 'locationId', //id is still required when using accessorFn instead of accessorKey
            header: 'Location',
            size: 100,
            Cell: ({ cell }) => {
              const { timezone } = cell.getValue();
              return (
                <div>
                  <p>{timezone}</p>
                </div>
              );
            },
          },
          // ============================ CANDIDATE STATUS COLUMN ============================
          {
            accessorFn: (row) => ({
              status: row.status,
              submissionId: row,
            }), //accessorFn used to join multiple data into a single cell
            id: 'statusId', //id is still required when using accessorFn instead of accessorKey
            header: 'Status',
            size: 100,

            Cell: ({ cell, row }) => {
              const { submissionId, status } = cell.getValue();

              const matchedStatus = statusMap.find((s) => s.value === status);

              return (
                <>
                  <select
                    id="status"
                    name="status"
                    style={{
                      backgroundColor: matchedStatus?.color || 'blue',
                      color: matchedStatus?.textColor || 'white',
                      textAlign: 'center',
                      borderRadius: '20px',
                      padding: '5px 10px',
                      width: '125px',
                      overflow: 'clip',
                      fontSize: '14px',
                    }}
                    value={status}
                    onChange={(e) => handleSubmissionChange(
                      submissionId.id,
                      { status: e.target.value },
                      setValue,
                      queryClient,
                      row,
                    )}
                  >
                    {statusMap.map((status) => (
                      <option value={status.value} key={status.value}>
                        {status.label}
                      </option>
                    ))}
                  </select>
                </>
              );
            },
          },
        ],
      },
    ],
    [data, value],
  );

  const table = useMaterialReactTable({
    //@ts-ignore
    columns,
    defaultColumn: {
      minSize: 20, //allow columns to get smaller than default
      maxSize: 20, //allow columns to get larger than default
      size: 10, //make columns wider by default
    },
    enableHiding: true,
    data,
    columnFilterDisplayMode: 'popover',
    enableColumnFilters: true,
    enableColumnOrdering: true,
    enableGrouping: true,
    autoResetPageIndex: false,
    enableColumnPinning: true,
    enableFacetedValues: true,
    enableRowActions: true,
    enableRowSelection: true,
    initialState: {
      showColumnFilters: true,
      showGlobalFilter: true,
      columnPinning: {
        left: ['mrt-row-select', 'mrt-row-expand'],
        right: ['mrt-row-actions'],
      },
      columnFilters: [],

      columnVisibility: { removedFromSandbox: false },
    },
    paginationDisplayMode: 'pages',
    positionToolbarAlertBanner: 'bottom',
    muiSearchTextFieldProps: {
      size: 'small',
      variant: 'outlined',
    },
    muiPaginationProps: {
      color: 'secondary',
      rowsPerPageOptions: [5, 10, 20, 30],
      shape: 'rounded',
      variant: 'outlined',
    },
    muiTableContainerProps: {
      sx: {
        maxHeight: '100%',
        // height: 'calc(100vh - 128px)',
        height: '100%',
        width: '100%',
        overflowX: 'auto',
      },
    },
    muiTableHeadCellProps: {
      sx: {
        padding: '0 8px', // Reduce padding between columns in header cells
      },
    },
    muiExpandButtonProps: ({ row, table }) => ({
      onClick: () => table.setExpanded({ [row.id]: !row.getIsExpanded() }),
    }),

    muiTableBodyCellProps: ({ row }) => ({
      onClick: () => {
        data
          .filter((candidateName) => `${candidateName.candidate.firstName} ${candidateName.candidate.lastName}` === row.getValue('name'))[0]
          .submissionVideo.replace('share', 'embed');
      },
      sx: {
        cursor: 'pointer',
        padding: '6px', // Reduce padding between columns in body cells
        width: '50px',
      },
    }),
    renderDetailPanel: ({ row }) => <RowDetailPanel row={row} data={data} />,

    renderRowActionMenuItems: ({ closeMenu, row }) => [
      <MenuItem
        key={0}
        onClick={() => {
          table.setEditingRow(row);
          closeMenu();
        }}
        sx={{ m: 0 }}
      >
        <ListItemIcon>
          <AccountCircle />
        </ListItemIcon>
        Edit Submission
      </MenuItem>,
      <MenuItem
        key={1}
        onClick={() => {
          setOpen(true);
          setSelectedFinalRoundRowData(row);
          closeMenu();
        }}
        sx={{ m: 0 }}
      >
        <ListItemIcon>
          <AccountCircle />
        </ListItemIcon>
        Init Final Round
      </MenuItem>,
      <MenuItem
        key={2}
        onClick={() => {
          // View profile logic...
          setOpen(true);
          setSelectedFinalRoundRowData(row);

          closeMenu();
        }}
        sx={{ m: 0 }}
      >
        <ListItemIcon>
          <AccountCircle />
        </ListItemIcon>
        Invite Eric/Laura to Final Round Team
      </MenuItem>,
    ],
    renderTopToolbar: ({ table }) => {
      return (
        <Box
          sx={(theme) => ({
            backgroundColor: lighten(theme.palette.background.default, 0.05),
            display: 'flex',
            gap: '0.5rem',
            p: '8px',
            justifyContent: 'space-between',
            borderBottom: '1px solid #D3D3D3',
          })}
        >
          <Box sx={{ display: 'flex', gap: '0.5rem', alignItems: 'center' }}>
            {/* import MRT sub-components */}

            {/* ==================HEADER SEARCH BAR================== */}
            <MRT_GlobalFilterTextField table={table} />

            {/* ===============HEADER HIDE COLS BUTTON=============== */}
            <MRT_ShowHideColumnsButton table={table} />

            {/* ============HEADER TOGGLE FILTERS BUTTON============= */}
            {/* <MRT_ToggleFiltersButton table={table} /> */}

            <button
              disabled={!table.getIsSomeRowsSelected()}
              style={{
                cursor: table.getIsSomeRowsSelected() ? "pointer" : "not-allowed",
              }}
              onClick={() => {
                handleArchiveSubmissions(table.getSelectedRowModel().rows, table);
              }}
            >
              <ArchiveIcon
                style={{
                  color: !table.getIsSomeRowsSelected() ? 'rgb(235, 235, 228)' : 'gray',
                }}
              />
            </button>
          </Box>
        </Box>
      );
    },
    // ================================== FOOTER ==================================
    renderBottomToolbarCustomActions: ({ table }) => {
      // Calculate total rows
      const rowCount = table.getRowModel().rows.length;
      return (
        <Box sx={{ display: 'flex', justifyContent: 'flex-end', padding: '1rem' }}>
          {toast}
          {selectedFinalRoundRowData ?

            <Dialog open={open} onClose={() => { setOpen(false); }}>
              <DialogTitle>Which Superday for {selectedFinalRoundRowData?.original?.candidate.firstName}</DialogTitle>
              <DialogContent>
                <div className='flex w-full justify-center align-center'>
                  <Button onClick={() => handleInitFinalRound("superday1")}>Superday 1</Button>
                  <Button onClick={() => handleInitFinalRound("superday2")}>Superday 2</Button>
                  <Button onClick={() => handleInitFinalRound("superday3")}>Superday 3</Button>
                </div>


                {isLoadingSuperday &&
                  <div className='flex w-full justify-center align-center'>
                    <CircularProgress />
                  </div>
                }
              </DialogContent>
            </Dialog> : null
          }
          <Typography variant="subtitle1">Total Rows: {rowCount}</Typography>
        </Box>
      );
    },
  });

  return <MaterialReactTable table={table} />;
};

export default HiringTable;
