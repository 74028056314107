import _ from 'lodash';
import { useEffect, useState, useCallback } from 'react';
import { NavLink, useSearchParams } from 'react-router-dom';

import { CartesianGrid, Legend, Line, LineChart, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';
import CustomTooltip from './CustomTooltip';

import { hslStringToRgbString, stringToColor } from '../../../utils/colorUtils';
import { classNames } from '../../InvestigationBoard/helpers';
import { dataTypeMap, getDisplayName, storageMap } from '../helpers';
import { taskTypeMap } from '../types';

const buildChartDataFromInsights = (insights, weeks, prefix) => {
  const chartData = [];

  for (const week of weeks) {
    const weekObj = {
      name: week,
    };

    for (const typeGroup of Object.keys(taskTypeMap)) {
      const typeGroupData = insights[week]?.filter((x) => x.type === typeGroup) ?? [];
      const sumTasks = _.sumBy(typeGroupData, (obj) => parseFloat(obj['total_tasks'])) || 0;
      const sumTests = _.sumBy(typeGroupData, (obj) => parseFloat(obj['total_steps'])) || 0;
      weekObj[`${prefix ? prefix + ' | ' : ''}${taskTypeMap[typeGroup]} - ${typeGroup === 'testCreation' ? 'Workflows' : 'Tasks'}`] = sumTasks || 0;
      weekObj[`${prefix ? prefix + ' | ' : ''}${taskTypeMap[typeGroup]} - Tests`] = sumTests || 0;
    }

    chartData.push(weekObj);
  }

  return chartData;
};

const addAveragesToChartData = (chartData, denominator) => {
  for (const week of chartData) {
    for (const key of Object.keys(week)) {
      if (key === 'name') continue;
      week[`${key} | Average`] = (week[key] / denominator).toFixed(2) || 0;
    }
  }
};

function mergeWeeklyData(data) {
  const mergedData = {};

  // Iterate over each key in the data
  for (const key of Object.keys(data)) {
    data[key].forEach((week) => {
      const weekName = week.name;

      // If the week name is not already in mergedData, initialize it
      if (!mergedData[weekName]) {
        mergedData[weekName] = { name: weekName };
      }

      // Add the data from the current key to the corresponding week in mergedData
      for (const insight in week) {
        if (insight !== 'name') {
          mergedData[weekName][insight] = week[insight];
        }
      }
    });
  }

  // Convert the mergedData object to an array
  return Object.values(mergedData);
}

export default function NewWeeklyCreationChart({ data, filters, handleChangeQAEOnTeam = null, targetQAE = null }) {
  if (!data) {
    return <div>No data provided for creation chart</div>;
  }
  // Determine the name of the QA lead to use for sorting
  const leadName = data.team?.members?.find((m) => m.isLead)?.name || '';

  // get and parse search params
  const [searchParams, setSearchParams] = useSearchParams();
  const paramsMap = Object.fromEntries([...searchParams]);

  const renderLegend = (props) => {
    const { payload } = props;
    let legendGroups = _.groupBy(payload, (entry) => entry.value.split(' | ')[0]);

    // only show legend for target QAE if specified
    if (targetQAE) {
      legendGroups = _.pickBy(legendGroups, (g) => g.some((e) => e.value.includes(targetQAE.name) || e.value.includes('Team')));
    }

    return (
      <div className="flex justify-between">
        <div
          className={classNames(
            !targetQAE && 'w-full',
            'grid justify-between gap-2 p-2 text-sm',
            Object.keys(legendGroups).length >= 6 ? 'grid-cols-6' : `grid-cols-${Object.keys(legendGroups).length}`,
            targetQAE && 'grid-cols-2',
          )}
        >
          {Object.keys(legendGroups)
            .sort((a, b) => {
              if (a === 'Team') return 1; // Put team legend card at the end
              if (b === 'Team') return -1;

              if (a === leadName) return 1; // Put lead legend card second to last
              if (b === leadName) return -1;

              return a.localeCompare(b);
            })
            .map((group, groupIdx) => {
              const groupEntries = legendGroups[group];
              const displayName = getDisplayName(group);
              // determine image based on data type
              return (
                <div className="flex flex-col shadow p-2" key={groupIdx}>
                  <p className="flex items-center gap-x-4">
                    <img className="rounded-full h-10 w-auto" src={images[group]} alt="avatar" />
                    <span className="font-semibold">{displayName}</span>
                  </p>
                  <ul>
                    {groupEntries.map((entry, entryIdx) => {
                      return (
                        <li key={entryIdx} className={classNames('flex justify-start my-1 truncate', !visibility[entry.value] && 'text-gray-400')}>
                          <button
                            className="flex items-center"
                            onClick={() => handleLegendClick(entry)}
                            onMouseEnter={() => handleLegendEnter(entry)}
                            onMouseLeave={() => handleLegendLeave(entry)}
                          >
                            <span
                              className="h-3.5 w-3.5 mr-2 rounded-full"
                              style={{ backgroundColor: visibility[entry.value] ? entry.color : '#ccc' }}
                            />
                            <span className="truncate">{entry.value.match(/^[^|]*\| (.*)/)[1]}</span>
                          </button>
                        </li>
                      );
                    })}
                  </ul>
                </div>
              );
            })}
        </div>
        {targetQAE && (
          <div className="flex flex-1 flex-col items-center justify-center space-x-4 p-4">
            <NavLink
              to={`/team-insights?teamName=${data.team.name}&gte=${filters.gte}&lte=${filters.lte}`}
              className="flex flex-col justify-center items-center"
            >
              <img src={data.team.imageUrl} alt="avatar" className="h-14 w-auto rounded-full" />
              <span className="font-semibold">{data.team.name}</span>
            </NavLink>
            <div className="flex flex-col justify-center items-center">
              <span className="text-sm text-gray-600">Other members of this team:</span>
              <div className="flex gap-x-2">
                {data.team.members
                  .filter((m) => m.qawId !== targetQAE.qawId)
                  .map((m, mIdx) => (
                    <span key={mIdx} className="text-xs text-gray-400">
                      <button onClick={() => handleChangeQAEOnTeam(m.qawId)}>{m.name.split(' ')[0]}</button>
                    </span>
                  ))}
              </div>
            </div>
          </div>
        )}
      </div>
    );
  };

  /* LOCAL DATA MANIPULATION */

  // determine type of data we are making a chart for
  let dataType = data.qae ? 'qae' : data.team ? 'team' : 'pack';
  const creationAndOutliningData = data[dataTypeMap[dataType]];

  const images = {};
  if (dataType === 'qae') {
    images[data.qae.name] = data.qae.avatar48;
  } else if (dataType === 'team') {
    for (const member of data.team.members) {
      images[member.name] = member.avatar48;
    }
    images['Team'] = data.team.imageUrl;
  } else if (dataType === 'pack') {
    for (const team of data.packTeams) {
      images[team.name] = team.imageUrl;
      for (const member of team.members) {
        images[member.name] = member.avatar48;
      }
    }
    images['Pack'] = data.pack.avatar48;
  }

  // determine all unique weeks regardless of if there is data for an individual on that week
  const allWeeks = _.uniq(creationAndOutliningData.map((x) => x['Week Number']));

  // group data by qae name
  const groupedCreationAndOutliningData = new Object(_.groupBy(creationAndOutliningData, 'name'));

  // sub-group data by week number & build individual chart data or each qae
  for (const [name, data] of Object.entries(groupedCreationAndOutliningData)) {
    groupedCreationAndOutliningData[name] = _.groupBy(data, 'Week Number');
    groupedCreationAndOutliningData[name] = buildChartDataFromInsights(groupedCreationAndOutliningData[name], allWeeks, name);
  }

  // add chart data for entire data set
  if (['team', 'pack'].includes(dataType)) {
    const capitalizedType = _.capitalize(dataType);
    const denominator = dataType === 'team' ? data.team.members.length : data.packTeams.length;
    groupedCreationAndOutliningData[capitalizedType] = buildChartDataFromInsights(
      _.groupBy(creationAndOutliningData, 'Week Number'),
      allWeeks,
      capitalizedType,
    );
    addAveragesToChartData(groupedCreationAndOutliningData[capitalizedType], denominator);
  }

  // merge all insight types by week into a single array
  const chartData = mergeWeeklyData(groupedCreationAndOutliningData);

  // sort by week
  const sortedChartData = chartData.sort((a, b) => {
    const [yearA, weekA] = a.name.split(' ').map(Number);
    const [yearB, weekB] = b.name.split(' ').map(Number);

    if (yearA === yearB) {
      return weekB - weekA;
    } else {
      return yearB - yearA;
    }
  });

  // set state with already manipulated data
  const [visibility, setVisibility] = useState(
    paramsMap.visibleLines && paramsMap.visibleLines.split(',').length
      ? _.mapValues(sortedChartData[0], (_, k) => paramsMap.visibleLines.split(',').includes(k))
      : JSON.parse(sessionStorage.getItem(storageMap[dataType].creation)) ??
      _.mapValues(sortedChartData[0], (_, k) => k.includes('Creation - Tests')),
  );

  const [opacity, setOpacity] = useState(_.mapValues(sortedChartData[0], () => 1));

  const handleLegendEnter = useCallback(
    (o) => {
      const { dataKey } = o;

      // if the dataKey is not visible, do not change opacity
      if (!visibility[dataKey]) return;

      setOpacity((op) => {
        const newOpacity = { ...op };
        Object.keys(op).forEach((key) => {
          newOpacity[key] = key === dataKey ? 1.5 : 0.3;
        });
        return newOpacity;
      });
    },
    [visibility],
  );

  const handleLegendLeave = useCallback(
    (o) => {
      const { dataKey } = o;

      // if the dataKey is not visible, do not change opacity
      if (!visibility[dataKey]) return;

      setOpacity((op) => {
        const newOpacity = { ...op };
        Object.keys(op).forEach((key) => {
          newOpacity[key] = 1;
        });
        return newOpacity;
      });
    },
    [visibility],
  );

  const handleLegendClick = useCallback((o) => {
    const { dataKey } = o;

    setVisibility((vis) => {
      const newVisibility = { ...vis, [dataKey]: !vis[dataKey] };
      sessionStorage.setItem(storageMap[dataType].creation, JSON.stringify(newVisibility));
      return newVisibility;
    });
  }, []);

  useEffect(() => {
    const visibleLines = Object.keys(visibility)
      .filter((key) => visibility[key])
      .join(',');
    searchParams.set('visibleLines', visibleLines);
    setSearchParams(searchParams);
  }, [visibility]);

  return (
    <div className="flex-col items-center justify-center border">
      <ResponsiveContainer width="100%" height={1000}>
        <LineChart height={600} data={sortedChartData.reverse()} margin={{ right: 40, top: 10 }}>
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="name" tickMargin={5} />
          <YAxis />
          <Tooltip content={<CustomTooltip />} />
          <Legend wrapperStyle={{ textAlign: 'center', width: '100%' }} content={renderLegend} />
          {Object.keys(sortedChartData).length &&
            Object.keys(sortedChartData[0]).map((line, lineIdx) => {
              if (line === 'name') return null;
              if (targetQAE) {
                if (!line.includes(targetQAE.name) && !line.includes('Team')) return null;
              }
              return (
                <Line
                  key={lineIdx}
                  type="monotone"
                  dataKey={line}
                  dot={false}
                  stroke={hslStringToRgbString(stringToColor(line))}
                  strokeOpacity={line.includes('Team') ? 1 : opacity[line]}
                  strokeWidth={line.includes('Team') ? 4 : opacity[line] === 1.5 ? 3 : 1}
                  hide={!visibility[line]}
                />
              );
            })}
        </LineChart>
      </ResponsiveContainer>
    </div>
  );
}
