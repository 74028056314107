import { sendGetRequest, sendNoAuthGetRequest, sendPostRequest, sendPutRequest } from '../../utils/network';

export async function getAllSubmissions() {
  try {
    let response = await sendGetRequest(`/hiring-dashboard/submissions`);

    if (!response.success) {
      throw new Error('Failed to fetch new submissions. Please refresh the page.');
    }

    return response?.data || [];
  } catch (error) {
    throw new Error(error.message);
  }
}

export async function getAllJobSources() {
  try {
    let response = await sendNoAuthGetRequest(`/hiring-public/job-sources`);

    if (!response.success) {
      throw new Error('Failed to fetch new job sources. Please refresh the page.');
    }

    return response?.data || [];
  } catch (error) {
    throw new Error(error.message);
  }
}

export async function updateCandidateSubmission(submissionData) {
  try {
    let response = await sendPutRequest(`/hiring-dashboard/submission`, submissionData);
    if (!response.success) {
      throw new Error('Failed to update candidate submission. Please refresh the page.');
    }
    return response?.data || '';
  } catch (error) {
    throw new Error(error.message);
  }
}

export async function updateCandidateInfo(candidateData) {
  try {
    let response = await sendPutRequest(`/hiring-dashboard/candidate`, candidateData);
    if (!response.success) {
      throw new Error('Failed to update candidate info. Please refresh the page.');
    }
    return response?.data || '';
  } catch (error) {
    throw new Error(error.message);
  }
}

export async function initCandidateFinalRound(candidateData) {
  try {
    let response = await sendPostRequest(`/hiring-dashboard/initCandidateFinalRound`, candidateData);
    if (!response.success) {
      throw new Error('Failed to init new final round team. Please refresh the page.');
    }
    return response || '';
  } catch (error) {
    throw new Error(error.message);
  }
}

export async function inviteEricLauraToFinalRoundTeam(candidateData) {
  try {
    let response = await sendPostRequest(`/hiring-dashboard/inviteEricLauraToFinalRoundTeam`, candidateData);
    if (!response.success) {
      throw new Error('Failed to invite Eric / Laura Please refresh the page.');
    }
    return response?.data || '';
  } catch (error) {
    throw new Error(error.message);
  }
}

export async function getCandidateEmails(candidateEmail) {
  try {
    let response = await sendPostRequest(`/hiring-dashboard/getCandidateEmails`, { candidateEmail: candidateEmail });
    if (!response.success) {
      throw new Error('Failed to fetch new candidate Emails. Please refresh the page.');
    }
    return response?.data || '';
  } catch (error) {
    throw new Error(error.message);
  }
}

export async function sendCandidateEmail({ currentEditorState, candidate, singleMessageHeaders }) {
  try {
    let response = await sendPostRequest(`/hiring-dashboard/sendCandidateEmail`, { currentEditorState, candidate, singleMessageHeaders });

    if (!response.success) {
      throw new Error('Failed to send candidate email. Please refresh the page.');
    }
    return response?.data || '';
  } catch (error) {
    throw new Error(error.message);
  }
}
/**
 * Invites executives to the final round team for a specified candidate.
 *
 * This asynchronous function sends a POST request to the hiring dashboard API
 * to invite executives to the final round team for the given candidate/team ID.
 * @async
 * @function inviteExecsToFinalRoundTeam
 * @param {object} params - The parameters object.
 * @param {string|number} params.teamId - Id of the candidates' final round team
 * @returns {Promise<string>} A promise that resolves to the response data if the invitation is successful,
 *                            or an empty string if no data is returned.
 * @throws {Error} Throws an error if the invitation fails or if there is an issue with the request.
 * @example
 * // Usage example:
 * inviteExecsToFinalRoundTeam({ teamId: '12345' })
 *   .then(data => {
 *     console.log('Invitations sent successfully:', data);
 *   })
 *   .catch(error => {
 *     console.error('Error inviting executives:', error.message);
 *   });
 */
export async function inviteExecsToFinalRoundTeam({ teamId }) {
  try {
    let response = await sendPostRequest(`/hiring-dashboard/inviteExecsToFinalRoundTeam`, { teamId });

    if (!response.success) {
      throw new Error('Failed to invite Execs to final round team.');
    }
    return response?.data || '';
  } catch (error) {
    throw new Error(error.message);
  }
}


export async function archiveSubmissions({ ids }) {
  try {
    let response = await sendPutRequest(`/hiring-dashboard/archiveSubmissions`, { ids });

    if (!response.success) {
      throw new Error('Failed to archive candidate submission.');
    }
    return response?.data || '';
  } catch (error) {
    throw new Error(error.message);
  }
}

export async function unarchiveSingleSubmission({ id }) {
  try {
    let response = await sendPutRequest(`/hiring-dashboard/unarchiveSubmission`, { id });

    if (!response.success) {
      throw new Error('Failed to unarchive candidate submission.');
    }
    return response?.data || '';
  } catch (error) {
    throw new Error(error.message);
  }
}
