import { useState } from 'react';
import '../../css/hiring.css';
//@ts-ignore
import logo from './logo.svg';
import UploadAssignmentForm from './UploadAssignmentForm';

export default function HiringPublic() {
  const [readyToSubmit, setReadyToSubmit] = useState(false);
  const [showForm, setShowForm] = useState(true);
  const [serverError, setServerError] = useState(false);

  const submitTakehomeHandler = (state) => {
    setReadyToSubmit(state);
    setShowForm(true);
  };

  return (
    <div className="flex flex-col items-center bg-[#0c0c29] font-sans">
      <div className="bg-[#3D3DF5] h-24 w-full flex items-center justify-center">
        <img src={logo} alt="QAW Logo" className="w-[200px] h-[200px]" />
      </div>

      <main className="pr-[5%] pl-[5%] p-6 text-white main">
        <section className="mt-2 p-5 flex flex-col items-center heading bg-[#3939449d] rounded-[20px]">
          <p className="mb-4">
            Quality Assurance (QA) is one of the biggest unsolved problems in software development. Companies spend over $40 billion a year on
            software testing...with not-so-great results. Bugs continue to find their way to production, frustrating customers worldwide. Development
            teams also struggle with QA. Creating automated tests is difficult, tests take forever to run, and failures are hard to debug.
          </p>
          <p>
            <a href="https://www.qawolf.com/" target="_blank" rel="noreferrer" className="text-[#00ff71] no-underline hover:text-[#daff69] hover:underline">
              QA Wolf
            </a>{' '}
            is on a mission to help teams ship confidently. We automate companies' QA with zero effort by writing and maintaining automated tests.
            This involves a combination of cutting-edge technology and specialized QA Engineers (like you?!). We are backed by awesome investors
            including{' '}
            <a href="https://notation.vc/" target="_blank" rel="noreferrer" className="text-[#00ff71] no-underline hover:text-[#daff69] hover:underline">
              Notation Capital
            </a>
            , Sahil Lavingia (founder of Gumroad), and Naval Ravikant (founder of AngelList).
          </p>
        </section>

        <section className="job_requirements">
          <h2 className="text-2xl pt-[3%] font-bold mb-4 hiring-h2">Job Requirements</h2>
          <p className="mb-2">
            <b>
              <em>
                This job is 100% remote, though all candidates must be physically located and able to work in the United States, Canada, UK,
                Israel, or Australia. We do not sponsor work visas (H1B, etc.) at this time.
              </em>
            </b>
          </p>
          <p className="mb-4">
            We are looking for a QA Engineer to create and maintain automated tests for customers. You will be an integral part of delivering a
            magical customer experience and a foundational member of our growing team.
          </p>

          <u>
            <p className="mb-1">
              <b>In this role, we are looking for someone who:</b>
            </p>
          </u>
          <ul className="list-disc list-inside mb-4">
            <li className="ml-3">
              Has the needed technical chops
              <ul className="list-disc list-inside ml-4">
                <li>
                  Create and maintain tests in JavaScript/
                  <a href="https://playwright.dev/" target="_blank" rel="noreferrer" className="text-[#00ff71] no-underline hover:text-[#daff69] hover:underline">
                    Playwright
                  </a>
                </li>
                <li>Understand HTML and CSS</li>
                <li>Use Chrome DevTools to debug and reproduce failures</li>
              </ul>
            </li>
            <li>Communicates effectively and proactively</li>
            <li>Stays organized, as you will work on multiple customer accounts</li>
            <li>
              Is a self-starter and quick learner
              <ul className="list-disc list-inside ml-4">
                <li>Unblock yourself by reading documentation</li>
                <li>Work with us to improve our tooling and best practices</li>
              </ul>
            </li>
            <li>Is scrappy and excited to help shape QA Engineering</li>
            <li>Shares our values</li>
          </ul>

          <p className="mb-4">
            <b>
              This is a full time role (~40 hours a week), and in almost all cases you will be expected to work standard hours (such as Monday -
              Friday, around 9am - 5pm in your time zone).
            </b>{' '}
          </p>
        </section>

        <hr className="h-3px border-none rounded-md bg-custom-gradient"></hr>

        <section className="values">
          <h2 className="text-2xl pt-[3%] font-bold mb-4 hiring-h2">QA Wolf’s Values</h2>
          <ul className="list-disc list-inside mb-2">
            <b>Make magic</b>
            <ul className="list-disc list-inside ml-4">
              <li>Work backwards from the ideal customer experience</li>
              <li>Be an awesome collaborator with teammates</li>
            </ul>
            <b>Be open</b>
            <ul className="list-disc list-inside ml-4">
              <li>Give and receive feedback with an open mind</li>
              <li>Share information and concerns even when it's uncomfortable</li>
            </ul>
            <b>Have freedom and ownership</b>
            <ul className="list-disc list-inside ml-4">
              <li>Trust each other to do what is best for QA Wolf</li>
              <li>Own your outcomes</li>
              <li>Be comfortable dealing with ambiguity</li>
            </ul>

            <b>Deliver impact fast</b>
            <ul className="list-disc list-inside ml-4">
              <li>Understand that you can always be 1% better and that no one is perfect</li>
              <li>Take initiative to learn and develop yourself</li>
            </ul>
          </ul>
          <em>
            <p className="mb-4">
              Learn more about our Mission and Values{' '}
              <a
                href="https://www.notion.so/Mission-Values-859c7d0411ba41349e1b318f4e7abc8f?pvs=21"
                className="text-[#00ff71] no-underline hover:text-[#daff69] hover:underline"
                target="_blank"
                rel="noreferrer"
              >
                here
              </a>
            </p>
          </em>
        </section>

        <hr className="h-3px border-none rounded-md bg-custom-gradient"></hr>

        <section className="hiring_process">
          <h2 className="text-2xl pt-[3%] font-bold mb-4 hiring-h2">Hiring Process</h2>
          <ol className="list-decimal list-inside mb-4">
            <li>
              A take-home coding challenge that can be downloaded below
              <ul className="list-disc list-inside ml-4">
                <li>The instructions are in the README. Please download the assignment and follow the instructions to apply for the role</li>
                <li>
                  Upload the completed assignment by navigating to the bottom of this page
                </li>
                <li>We promise to give you a decision on your take-home within 2 weeks, usually much faster</li>
              </ul>
            </li>
            <li>If that goes well, a 2-hour work session with us (paid)</li>
            <li>If that goes well, a short conversation with our Senior Director of QA Engineering</li>
            <li>If that goes well, you join us full time 🎉</li>
          </ol>
        </section>

        <hr className="h-3px border-none rounded-md bg-custom-gradient"></hr>

        <section className="salary_benefits">
          <h2 className="text-2xl pt-[3%] font-bold mb-4 hiring-h2">Salary & Benefits</h2>
          <ul className="list-disc list-inside mb-4">
            <li>
              Starting salary of <b>$80,000 / year (USD)</b>, with opportunities for promotion
              <ul className="list-disc list-inside ml-4">
                <li>If you are outside the US, salary will be converted to local currency equivalent</li>
              </ul>
            </li>
            <li>
              QA Wolf provides a comprehensive benefit package for all QA Engineers - the specifics will depend on your country of residence - learn
              more about our benefits{' '}
              <a
                href="https://www.notion.so/Benefits-7b78abe65de44d1ab1b9315b300b821e?pvs=21"
                className="text-[#00ff71] no-underline hover:text-[#daff69] hover:underline"
              >
                here
              </a>
            </li>
            <li>28 days paid time off</li>
          </ul>
        </section>

        <hr className="h-3px border-none rounded-md bg-custom-gradient"></hr>

        <section className="career_growth">
          <h2 className="text-2xl font-bold pt-[3%] mb-4 hiring-h2">Career Growth</h2>
          <ul className="list-disc list-inside mb-4">
            <li>QA Wolf provides career growth opportunities for all QA Engineers</li>
            <li>
              Our current career ladder is outlined{' '}
              <a
                href="https://www.notion.so/QA-Engineer-Career-Path-1a0974d2a78d4a25921a19c0ad24eb6f?pvs=21"
                className="text-[#00ff71] no-underline hover:text-[#daff69] hover:underline"
                target='_blank'
                rel="noreferrer"
              >
                here
              </a>
              , note that all incoming hires will start as a QA Engineer I
            </li>
          </ul>
        </section>

        <hr className="h-3px border-none rounded-md bg-custom-gradient"></hr>

        <section className="EEO mt-6">
          <p className="mb-4">
            We are an Equal Employment Opportunity ("EEO") Employer. It has been and will continue to be a fundamental policy of QA Wolf not to
            discriminate on the basis of race, color, creed, religion, gender, gender identity, pregnancy, marital status, partnership status,
            domestic violence victim status, sexual orientation, age, national origin, alienage or citizenship status, veteran or military status,
            disability, medical condition, genetic information, caregiver status, unemployment status or any other characteristic prohibited by
            federal, state and/or local laws. This policy applies to all aspects of employment, including hiring, promotion, demotion, compensation,
            training, working conditions, transfer, job assignments, benefits, layoff, and termination.
          </p>
          <p className="mb-4">
            As mentioned above, all candidates must be physically located and able to work in the United States, Canada, UK, Israel, or
            Australia. <b>Any applications from candidates outside of these locations will be automatically rejected.</b>
          </p>
          <p className="mb-4">
            Questions? Reach out to{' '}
            <a href="mailto:qa-hiring@qawolf.com" className="text-[#00ff71] no-underline hover:text-[#daff69] hover:underline">
              qa-hiring@qawolf.com
            </a>{' '}
            😊
          </p>
        </section>
      </main>
      <div id="submission-form" className="m-5 flex flex-col w-4/5 text-white items-center form-container bg-[#3939449d] rounded-[20px]">
        {showForm &&
          <>
            <h2 className="text-2xl pt-[3%] text-white font-bold mb-2 mt-5">Interested? Apply below! 🐺</h2>

            {!readyToSubmit ? (
              <>
                <a
                  href="https://res.cloudinary.com/huht2rslk/raw/upload/v1724444817/hiring%20internal/QA_Wolf_Take_Home.zip"
                  download
                  className=" flex justify-center items-center self-center mt-6 mb-4 rounded-md bg-indigo-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                >
                  {' '}
                  Download Take Home Assignment
                </a>

                <span className="text-white-500 pb-[3%]">
                  Ready to submit?{' '}
                  <button className="pt-[5%] pb-[5%] text-blue-500 hover:underline" onClick={() => submitTakehomeHandler(true)}>
                    Click here
                  </button>
                </span>
              </>
            ) : (
              <>
                <UploadAssignmentForm setShowForm={setShowForm} setServerError={setServerError} />

                <span className="text-white-500 pb-[3%]">
                  Need to download the assignment?{' '}
                  <button className="pt-[5%] pb-[5%] text-blue-500 hover:underline" onClick={() => submitTakehomeHandler(false)}>
                    Start here
                  </button>
                </span>
              </>
            )}
          </>
        }
        {!showForm &&
          <div className='p-[3%]'>
            {!serverError ?
              <div>
                <b className="text-2xl pt-[3%] text-white font-bold mb-2 mt-5">
                  Application submitted!
                </b>
                <p className="mb-5 mt-2 pb-[3%]">
                  Thank you for applying! We promise to give you a decision on your take-home within 2 weeks, but usually much faster!
                </p>
              </div>
              : <div>
                <b className="text-2xl pt-[3%] text-white font-bold mb-2 mt-5">
                  There was a server error, please try again.
                </b>
              </div>}
            <span className="text-white-500">
              New Submission?{' '}
              <button className="pt-[5%] text-blue-500 hover:underline" onClick={() => submitTakehomeHandler(true)}>
                Click here
              </button>
            </span>
          </div>
        }
      </div>
    </div>
  );
}
