/* eslint-disable eol-last */
import { updateCandidateSubmission, updateCandidateInfo, archiveSubmissions } from '../api';
import { archivedSubmissionStatusMap } from '../helpers/constants';

// Handle submission changes for a specific row
export const handleSubmissionChange = async (submissionId, newInfo, setValue, queryClient, row) => {


    await updateCandidateSubmission({
        submission: {
            id: submissionId,
            ...newInfo,
        },
    })
        .then(() => {
            setValue((prev) => ({
                ...prev,
                [submissionId]: {
                    ...prev[submissionId], // Preserve existing fields
                    ...newInfo, // Overwrite with new information
                },
            }));
        })
        .finally(async () => {
            if (archivedSubmissionStatusMap.includes(newInfo.status)) {
                let submissionsToBeArchived = row.original.id;
                await archiveSubmissions({ ids: [submissionsToBeArchived] });
            }

            queryClient.refetchQueries({ queryKey: ['combinedData'] });
        });
};

// Handle submission changes for a specific row
export const handleCandidateInfoChange = async (candidateId, newInfo, setValue, queryClient) => {
    await updateCandidateInfo({
        candidate: {
            id: candidateId,
            ...newInfo,
        },
    })
        .then(() => {
            setValue((prev) => ({
                ...prev,
                [candidateId]: {
                    ...prev[candidateId], // Preserve existing fields
                    ...newInfo, // Overwrite with new information
                },
            }));
        })
        .finally(() => {
            queryClient.refetchQueries({ queryKey: ['combinedData'] });
        });
};