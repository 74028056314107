import { useState, useEffect } from 'react';
import JSZip from 'jszip';

import Editor from '@monaco-editor/react';
import HiringFileExplorer from './HiringFileExplorer';

import { getLanguageFromFileName } from './helpers';

const MonacoEditor = ({ fileUrl, candidateName }) => {
  const [fileList, setFileList] = useState([]);
  const [currentFile, setCurrentFile] = useState('');
  const [fileContent, setFileContent] = useState('');
  // const [currView, setCurrView] = useState(false);

  const downloadSubmission = () => {
    const zip = new JSZip();

    // Loop through the files array and add each file to the ZIP
    fileList.forEach((file) => {
      zip.file(file.name, file.content); // Add the file with its name and content
    });

    // Generate the ZIP file as a Blob and trigger the download
    zip.generateAsync({ type: 'blob' }).then(function (content) {
      const link = document.createElement('a');
      link.href = URL.createObjectURL(content);
      link.download = `${candidateName.firstName} ${candidateName.lastName} - TakeHome.zip`; // The name of the downloaded ZIP file
      link.click(); // Simulate a click to start the download
    });
  };

  const handleFileSelect = (file) => {
    console.log(fileList);
    setCurrentFile(file.name);
    setFileContent(file.content);
  };

  const handleEditorChange = (value) => {
    setFileContent(value);
  };

  const fetchAndExtractZip = async () => {
    try {
      const response = await fetch(fileUrl); // Replace `fileUrl` with the actual URL of the zip file
      if (!response.ok) throw new Error('Network response was not ok.');

      const zipData = await response.arrayBuffer();
      const zip = await JSZip.loadAsync(zipData);

      const filesArray = [];

      await Promise.all(
        Object.keys(zip.files).map(async (relativePath) => {
          const zipEntry = zip.files[relativePath];

          if (!zipEntry.dir && !zipEntry.name.includes('MACOSX') && !zipEntry.name.includes('node_modules')) {
            const blob = await zipEntry.async('blob');

            const content = await new Promise((resolve) => {
              const reader = new FileReader();
              reader.onload = (e) => resolve(e.target.result);
              reader.readAsText(blob);
            });

            filesArray.push({
              type: getLanguageFromFileName(zipEntry.name),
              name: zipEntry.name,
              content: content,
            });
          }
        }),
      );

      setFileList(filesArray);

      // Set default file as `index.js` or any file that matches the condition
      const indexFile = filesArray.find((file) => file.name.includes('index.js'));
      if (indexFile) {
        setCurrentFile(indexFile.name);
        setFileContent(indexFile.content);
      } else if (filesArray.length > 0) {
        // Fallback to the first file if no `index.js` file is found
        setCurrentFile(filesArray[0].name);
        setFileContent(filesArray[0].content);
      }
    } catch (error) {
      console.error('Error fetching or extracting zip:', error);
    }
  };

  useEffect(() => {
    fetchAndExtractZip();
  }, []);

  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'space-between',
        height: '100%',
        width: '100%',
        background: '#111',
        color: '#d4d4d4',
        // overflow: 'scroll',
        position: 'absolute',
      }}
    >
      <HiringFileExplorer files={fileList} onFileSelect={handleFileSelect} />
      <div style={{ width: '85%' }}>
        <div style={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
          <h3
            style={{
              padding: 10,
            }}
          >
            {currentFile}
          </h3>
          <button
            onClick={downloadSubmission}
            style={{ cursor: 'pointer', width: '200px', border: '1px solid grey', marginRight: '10px', height: '80%' }}
          >
            Download Submission
          </button>
        </div>
        <Editor
          width={'100%'}
          height={'100%'}
          language={getLanguageFromFileName(currentFile)}
          theme="vs-dark"
          value={fileContent}
          onChange={handleEditorChange}
        />
      </div>
    </div>
  );
};

export default MonacoEditor;
