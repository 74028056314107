import { MaterialReactTable, useMaterialReactTable, MRT_ColumnDef, MRT_Row } from 'material-react-table';
import { useMemo } from 'react';
import MainDrawer from '../ClientMilestones/MainDrawer';
import ClientSummaryTableToolBar from './ToolBar';
import ProjectPlanStatusCell from './ProjectPlanStatusCell';
import { ClientSummaryTableRow } from '../types';

const styles = {
  endColumn: {
    sx: {
      borderRight: '1px solid rgba(224, 224, 224, 1)',
    },
  },
};

const getMilestoneCellProps = ({ row }: { row: MRT_Row<ClientSummaryTableRow> }) => ({
  sx: {
    backgroundColor: row.original.activeMilestone
      ? row.original.isOnTrack
        ? '#e8f5e9' // light green
        : '#ffebee' // light red
      : '#fff3e0', // light yellow
  },
});

export default function ClientSummaryTable({ data }: { data: ClientSummaryTableRow[] }) {
  const initialState = {
    pagination: {
      pageIndex: 0,
      pageSize: 100,
    },
  };

  const columns = useMemo<MRT_ColumnDef<ClientSummaryTableRow>[]>(() => {
    return [
      {
        id: 'qawTeam',
        header: 'QAW Team',
        enableResizing: false,
        columns: [
          {
            header: 'Lead',
            accessorKey: 'qaLead.name',
            filterVariant: 'multi-select',
          },
          {
            header: 'Manager',
            accessorKey: 'qaManager.name',
            filterVariant: 'multi-select',
          },
          {
            header: 'CSM',
            accessorKey: 'csm.name',
            filterVariant: 'multi-select',
            muiTableBodyCellProps: styles.endColumn,
          },
        ],
      },
      {
        id: 'projectPlan',
        header: 'Project Plan',
        enableResizing: false,
        columns: [
          {
            header: 'Client Name',
            accessorKey: 'name',
            filterVariant: 'multi-select',
          },

          {
            header: 'Status',
            accessorKey: 'isProjectPlanComplete',
            filterVariant: 'checkbox',

            muiFilterCheckboxProps: {
              title: 'Done?',
            },
            muiTableBodyCellProps: styles.endColumn,
            Cell: ProjectPlanStatusCell,
            minSize: 200,
          },
        ],
      },
      {
        id: 'milestones',
        header: 'Milestones',
        enableColumnActions: true,
        enableResizing: false,
        columns: [
          {
            accessorKey: 'testsScheduledForCurrentWeek',
            header: 'Tests Scheduled This Week',
            enableColumnFilter: false,
            sortUndefined: 1,
            muiTableBodyCellProps: ({ row }) => {
              const hasMilestone = row.original.activeMilestone;
              const hasEnoughTestsScheduled = row.original.testsScheduledForCurrentWeek >= row.original.requiredVelocity;
              return {
                sx: {
                  backgroundColor: !hasMilestone ? '#fff3e0' : hasEnoughTestsScheduled ? '#e8f5e9' : '#ffebee',
                },
              };
            },
          },
          {
            accessorKey: 'requiredVelocity',
            header: 'Req. Velocity',
            enableColumnFilter: false,
            sortUndefined: 1,
            muiTableBodyCellProps: getMilestoneCellProps,
            accessorFn: (row) => Math.max(row.requiredVelocity, 0),
          },
          {
            accessorKey: 'weeksRemaining',
            header: 'Weeks Remaining',
            enableColumnFilter: false,
            sortUndefined: 1,
            Cell: ({ row }) => (row.original.activeMilestone ? row.original.weeksRemaining : 'N/A'),
            muiTableBodyCellProps: getMilestoneCellProps,
          },
          {
            accessorKey: 'isOnTrack',
            header: 'Milestone Status',
            filterVariant: 'multi-select',
            muiFilterCheckboxProps: { title: 'On Track?' },
            accessorFn: (row) => (row.isOnTrack && row.activeMilestone ? 'On Track' : row.activeMilestone ? 'Behind' : 'N/A'),
            muiTableBodyCellProps: getMilestoneCellProps,
          },
          {
            accessorKey: 'activeMilestone',
            header: 'Active Milestone',
            accessorFn: (row) => !!row.activeMilestone,
            filterVariant: 'checkbox',
            muiFilterCheckboxProps: {
              title: 'Has Active Milestone?',
            },
            sortUndefined: 1,
            Cell: ({ row }) => <MainDrawer client={row.original} />,
            minSize: 200,
            muiTableBodyCellProps: getMilestoneCellProps,
          },
        ],
      },
    ];
  }, []);

  const table = useMaterialReactTable({
    columns,
    data,
    initialState,
    columnFilterDisplayMode: 'popover',
    enableColumnResizing: true,
    enableColumnActions: true,
    layoutMode: 'grid',
    // Automatically de-duplicates column filter options
    enableFacetedValues: true,
    renderTopToolbar: () => <ClientSummaryTableToolBar table={table} />,
  });

  return <MaterialReactTable table={table} />;
}
