// React imports
import { useState } from "react";

const EditableCell = ({ initialValue, onSave, placeholder, style, type = "input", options = [] }) => {
  const [value, setValue] = useState(initialValue);
  const [isEditing, setIsEditing] = useState(false);

  // Handle input change
  const handleChange = (e) => {
    setValue(e.target.value);
  };

  // Handle blur event to save changes
  const handleBlur = () => {
    setIsEditing(false);
    onSave(value); // Call onSave with the current value
  };

  // Handle click to enable editing
  const handleClick = () => {
    setIsEditing(true);
  };

  return (
    <div onClick={handleClick} style={{ cursor: "pointer", ...style }}>
      {isEditing ? (
        type === "select" ? (
          <select
            value={value}
            onChange={handleChange}
            onBlur={handleBlur}
            autoFocus
            style={{
              width: '100%',
              border: '1px solid #ccc',
              borderRadius: '4px',
              padding: '8px',
            }}
          >
            <option value="">{`Select option`}</option>
            {options.map((option) => (
              <option key={option} value={option}>
                {option}
              </option>
            ))}
          </select>
        ) : (
          <input
            type="text"
            value={value}
            onChange={handleChange}
            onBlur={handleBlur}
            placeholder={placeholder}
            autoFocus
            style={{
              width: '100%',
              border: '1px solid #ccc',
              borderRadius: '4px',
            }}
          />
        )
      ) : (
        <span>{value || placeholder}</span>
      )}
    </div>
  );
};

export default EditableCell;
