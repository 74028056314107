import { Fragment, useState } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import {
  ArrowsUpDownIcon,
  Bars3Icon,
  CalendarIcon,
  CheckIcon,
  Cog6ToothIcon,
  XMarkIcon,
  ChartBarIcon,
  ChartPieIcon,
  PresentationChartLineIcon,
  UserGroupIcon,
  BookOpenIcon,
  DocumentArrowDownIcon,
  TrophyIcon,
} from '@heroicons/react/24/outline';
import { BubbleChartOutlined, CelebrationOutlined, CakeOutlined } from '@mui/icons-material';
import { Outlet, Form, NavLink } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import { sendGetRequest } from '../../utils/tanstackNetwork';
import WolfLoader from '../WolfLoader/WolfLoader';

// hiring team member
const hiringTeamMembers = [
  "jonathant@qawolf.com",
  "amanda@qawolf.com",
  "laura@qawolf.com",
  "erice@qawolf.com",
];

const shouldShowCustomerTeamAssignments = (user) => {
  if (!user.is_qawolf) return false;
  if (user.isLead || user.isManager || user.isCSM) return true;
  return !user.isManager && !user.isCSM && !user.isQAE;
};

const shouldShowHiringDashboard = (user) => {
  if (!user.is_qawolf) return false;
  if (hiringTeamMembers.includes(user.email)) return true;
};

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

export default function MainLayout() {
  const [sidebarOpen, setSidebarOpen] = useState(false);

  const generalNavigation = [
    {
      name: 'Triage',
      href: '/triage',
      icon: ArrowsUpDownIcon,
    },
    {
      name: 'Tasks',
      href: '/tasks',
      icon: CheckIcon,
    },
    {
      name: 'Client Summary',
      href: '/client-summary',
      icon: CalendarIcon,
    },
    {
      name: 'Book of Business',
      href: '/book-of-business',
      icon: BookOpenIcon,
    },
    {
      name: 'Executive Dashboard',
      href: '/executive-dashboard',
      icon: CakeOutlined,
    },
    {
      name: 'Insights Explorer',
      href: '/insights-explorer',
      icon: ChartPieIcon,
    },
    {
      name: 'Insights Playground',
      href: '/insights-playground',
      icon: CelebrationOutlined,
    },
    {
      name: 'Stream Explorer',
      href: '/stream-explorer-nav',
      icon: PresentationChartLineIcon,
    },
    {
      name: 'Flow Explorer',
      href: '/flow-explorer/clwex0nha1vp001va30vudnlu',
      icon: ChartBarIcon,
    },
    {
      name: 'Reports',
      href: '/reports',
      icon: DocumentArrowDownIcon,
    },
    {
      name: 'Investigation Metrics',
      href: 'investigation-metrics',
      icon: BubbleChartOutlined,
    },
    {
      name: 'Howl Creation Competition',
      href: '/howl-utilization',
      icon: TrophyIcon,
    },
  ];

  // Grab the user from local storage
  let user = localStorage.getItem('user');
  user = JSON.parse(user);

  // Customer Team Assignment set only for managers, leads, csms, and automation
  if (user) {
    const customerTeamAssignment = {
      name: 'Customer Team Assignments',
      href: 'customer-team-assignments',
      icon: UserGroupIcon,
    };

    if (shouldShowCustomerTeamAssignments(user)) {
      generalNavigation.push(customerTeamAssignment);
    }

    const hiringDashboard = {
      name: 'Hiring Dashboard',
      href: 'hiring-dashboard',
      icon: UserGroupIcon,
    };

    if (shouldShowHiringDashboard(user)) {
      generalNavigation.push(hiringDashboard);
    }
  }

  // Always push settings into Nav at the end
  const settings = {
    name: 'Settings',
    href: '/settings',
    icon: Cog6ToothIcon,
  };
  generalNavigation.push(settings);

  // Query users in top level component
  const { isLoading: isLoading_getQawUserData, isError: isError_getQawUserData } = useQuery({
    queryKey: ['getAllQAWUsers'],
    queryFn: () => sendGetRequest('/list-all-qaw-users'),
    refetchInterval: 60 * 1000 * 60,
  });

  if (isLoading_getQawUserData) return <WolfLoader />;
  if (isError_getQawUserData) return <div>Error while querying Tanstack</div>;

  return (
    <>
      <div>
        <Transition.Root show={sidebarOpen} as={Fragment}>
          <Dialog as="div" className="relative z-40 md:hidden" onClose={setSidebarOpen}>
            <Transition.Child
              as={Fragment}
              enter="transition-opacity ease-linear duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="transition-opacity ease-linear duration-300"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="fixed inset-0 bg-gray-600 bg-opacity-75" />
            </Transition.Child>

            <div className="fixed inset-0 z-40 flex">
              <Transition.Child
                as={Fragment}
                enter="transition ease-in-out duration-300 transform"
                enterFrom="-translate-x-full"
                enterTo="translate-x-0"
                leave="transition ease-in-out duration-300 transform"
                leaveFrom="translate-x-0"
                leaveTo="-translate-x-full"
              >
                <Dialog.Panel className="relative flex w-full max-w-xs flex-1 flex-col bg-gray-800">
                  <Transition.Child
                    as={Fragment}
                    enter="ease-in-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in-out duration-300"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                  >
                    <div className="absolute top-0 right-0 -mr-12 pt-2">
                      <button
                        type="button"
                        className="ml-1 flex h-10 w-10 items-center justify-center rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
                        onClick={() => setSidebarOpen(false)}
                      >
                        <span className="sr-only">Close sidebar</span>
                        <XMarkIcon className="h-6 w-6 text-white" aria-hidden="true" />
                      </button>
                    </div>
                  </Transition.Child>
                  <div className="h-0 flex-1 overflow-y-auto pt-5 pb-4">
                    <div className="flex flex-shrink-0 items-center px-4">
                      <img
                        className="h-8 w-auto"
                        src="https://assets-global.website-files.com/6260298eca091b57c9cf188e/6260298eca091b8710cf18ea_logo.svg"
                        alt="Task Wolf"
                      />
                    </div>
                    <nav className="mt-5 space-y-1 px-2">
                      {generalNavigation.map((item) => (
                        <NavLink
                          key={item.name}
                          to={item.href}
                          className={({ isActive }) =>
                            classNames(
                              isActive ? 'bg-gray-900 text-white' : 'text-gray-300 hover:bg-gray-700 hover:text-white',
                              'group flex items-center px-2 py-2 text-base font-medium rounded-md',
                            )
                          }
                        >
                          <item.icon className="text-gray-300 mr-3 flex-shrink-0 h-6 w-6" aria-hidden="true" />
                          {item.name}
                        </NavLink>
                      ))}
                    </nav>
                  </div>
                  <div className="flex flex-shrink-0 bg-gray-700 p-4 items-center group">
                    <Form method="post" action="/logout">
                      <button
                        type="submit"
                        className="inline-block rounded-md border border-transparent bg-white py-2 px-4 text-base font-medium text-indigo-600 hover:bg-indigo-50"
                      >
                        Log Out
                      </button>
                    </Form>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
              <div className="w-14 flex-shrink-0">{/* Force sidebar to shrink to fit close icon */}</div>
            </div>
          </Dialog>
        </Transition.Root>

        {/* Static sidebar for desktop */}
        <div className="hidden md:fixed md:inset-y-0 md:flex md:w-48 md:flex-col">
          {/* Sidebar component, swap this element with another sidebar if you like */}
          <div className="flex min-h-0 flex-1 flex-col bg-gray-800">
            <div className="flex flex-1 flex-col overflow-y-auto pt-5 pb-4">
              <div className="flex flex-shrink-0 items-center px-4">
                <img
                  className="h-8 w-auto"
                  src="https://assets-global.website-files.com/6260298eca091b57c9cf188e/6260298eca091b8710cf18ea_logo.svg"
                  alt="Task Wolf"
                />
                <h1 className="text-white ml-4 text-xl">Task Wolf</h1>
              </div>
              <nav className="mt-5 flex-1 space-y-1 px-2">
                {generalNavigation.map((item) => (
                  <NavLink
                    key={item.name}
                    to={item.href}
                    className={({ isActive }) =>
                      classNames(
                        isActive ? 'bg-gray-900 text-white' : 'text-gray-300 hover:bg-gray-700 hover:text-white',
                        'group flex items-center px-2 py-2 text-sm font-medium rounded-md',
                      )
                    }
                  >
                    <item.icon className="text-gray-300 mr-3 flex-shrink-0 h-6 w-6" aria-hidden="true" />
                    {item.name}
                  </NavLink>
                ))}
              </nav>
            </div>
            <div className="flex flex-shrink-0 bg-gray-700 p-4 items-center">
              <Form method="post" action="/logout">
                <button
                  type="submit"
                  className="inline-block rounded-md border border-transparent bg-white py-2 px-4 text-base font-medium text-indigo-600 hover:bg-indigo-50"
                >
                  Log Out
                </button>
              </Form>
            </div>
          </div>
        </div>
        <div className="flex flex-1 flex-col md:pl-48">
          <div className="sticky top-0 z-10 bg-gray-100 pl-1 pt-1 sm:pl-3 sm:pt-3 md:hidden">
            <button
              type="button"
              className="-ml-0.5 -mt-0.5 inline-flex h-12 w-12 items-center justify-center rounded-md text-gray-500 hover:text-gray-900 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500"
              onClick={() => setSidebarOpen(true)}
            >
              <span className="sr-only">Open sidebar</span>
              <Bars3Icon className="h-6 w-6" aria-hidden="true" />
            </button>
          </div>
          <main className="flex-1">
            <div className="mt-6 pb-6 mx-auto px-2 sm:px-1">
              <Outlet />
            </div>
          </main>
        </div>
      </div>
    </>
  );
}
