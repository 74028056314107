export function getLanguageFromFileName (fileName) {
    const extension = fileName.split('.').pop();
    switch (extension) {
      case 'js': return 'javascript';
      case 'ts': return 'typescript';
      case 'html': return 'html';
      case 'css': return 'css';
      case 'json': return 'json';
      case 'md': return 'markdown';
      default: return 'plaintext';
    }
  }
