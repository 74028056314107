import _ from 'lodash';

import { computeDataMedian, computeMedian } from '../helpers.js';
import { roundToTwo } from '../../InsightsPlayground/helpers.js';

import { QaeCreationInsights, QAEInvestigationDataObject, SumObject, TeamCreationInsights, TeamInvestigationData, User } from '../types.js';
import { QaTeam } from '../../Planning/Summary/types.js';

// fixture colors
const EXCEEDING_EXPECTATIONS = 'bg-purple-50';
const MEETING_EXPECTATIONS = '';
const BELOW_EXPECTATIONS = 'bg-red-50';

/*
---------------------------------------------- GENERAL HELPERS ----------------------------------------------
*/
// #region general

/**
 * Returns the expected contribution for each unique position in the team based on the total team size and the number of each position on the team.
 */
function getExpectedContributionsForTeam<T extends string>(allPositions: T[]): Record<T, number> {
  // define position weights
  const knownPositionWeights: Record<string, number> = {
    'QA Engineer 1': 1,
    'QA Engineer 2': 1.3,
    'QA Engineer 3': 1.375,
  };

  // get position counts and number of non-lead members on team
  const positionCounts = _.countBy(allPositions);

  // calculate total weight (account for num members of each position)
  const totalWeight = Object.entries(positionCounts).reduce((acc, [position, count]) => acc + (knownPositionWeights[position] ?? 0) * count, 0);

  // initialize expected contributions object
  const expectedContributions = {} as Record<T, number>;

  // get distinct positions
  const distinctPositions = Array.from(new Set(allPositions));

  // calculate the expected contribution for each distinct position
  for (const position of distinctPositions) {
    const weight = knownPositionWeights[position] ?? 0;
    expectedContributions[position] = (weight / totalWeight) * 100;
  }

  return expectedContributions;
}

/**
 * Returns the tooltip text for the an individual users comparison table.
 */
export function getComparisonTableTooltipText(allPositions: string[], qae: User): string {
  const individualPosition = qae.position;
  const expectedContributions = getExpectedContributionsForTeam(allPositions);

  return `Based on your position as a ${individualPosition}, your expected contribution to the team is ${expectedContributions[
    individualPosition
  ].toFixed(2)}% of the total team's output.\n
  This is calculated by taking the weight of your position and dividing it by the total weight of all positions on the team.\n
  🟣 If a cell value is at or greater than your expected contribution, you are meeting or exceeding expectations and the cell will be purple.\n
  👌 If a cell value is within -15% of your expected contribution, you are in an acceptable range with room for improvement, and the cell will not be colored.\n
  🟥 If a cell value is less than 15% of your expected contribution, you may not be meeting expectations and the cell will be red.`;
}

// #endregion

/*
------------------------------------- CREATION COMPARISON TABLE HELPERS -------------------------------------
*/
// #region creation

// New interface for the return type
export interface CreationComparisonInsights {
  'Workflows Created': number;
  'Tests Created': number;
  'Outlining Tasks Completed': number;
  'Average Tests Created Per Week': number;
  'Median Tests Created Per Week': number;
  'Max Tests Per Week': number;
  'Min Tests Per Week': number;
}

export const computeQAEInsightsForCreationCompTable = (insights: QaeCreationInsights, sums: SumObject): CreationComparisonInsights => {
  const creationInsights = insights.qaeCreationByWeekAndType.filter((m) => m.type === 'testCreation');
  const maxTestPerWeek = _.maxBy(creationInsights, (w) => parseInt(w.total_steps));
  const minTestsPerWeek = _.minBy(creationInsights, (w) => parseInt(w.total_steps));

  const qaeInsights: CreationComparisonInsights = {
    'Workflows Created': sums.sumsByType.testCreation?.sumTasks ?? 0,
    'Tests Created': sums.sumsByType.testCreation?.sumTests ?? 0,
    'Outlining Tasks Completed': sums.sumsByType.outline?.sumTasks ?? 0,
    'Average Tests Created Per Week': parseInt((sums.sumsByType.testCreation?.sumTests / Object.keys(creationInsights).length).toFixed(1)) || 0,
    'Median Tests Created Per Week': computeDataMedian(creationInsights.map((m) => parseInt(m.total_steps))) || 0,
    'Max Tests Per Week': parseInt(maxTestPerWeek?.total_steps || '0') || 0,
    'Min Tests Per Week': parseInt(minTestsPerWeek?.total_steps || '0') || 0,
  };

  return qaeInsights;
};

export const computeTeamInsightsForCreationCompTable = (insights: TeamCreationInsights, sums: SumObject): CreationComparisonInsights => {
  const teamLead = insights.team.members.find((member) => member.isLead);

  const creationInsights = insights.teamCreationByWeekAndType.filter((m) => m.type === 'testCreation' && m.completed_by_qaw_id !== teamLead?.qawId);
  const maxTestPerWeek = _.maxBy(creationInsights, (w) => parseInt(w.total_steps));
  const minTestsPerWeek = _.minBy(creationInsights, (w) => parseInt(w.total_steps));

  const teamInsights = {
    'Workflows Created': sums.sumsByType.testCreation?.sumTasks ?? 0,
    'Tests Created': sums.sumsByType.testCreation?.sumTests ?? 0,
    'Outlining Tasks Completed': sums.sumsByType.outline?.sumTasks ?? 0,
    'Average Tests Created Per Week': parseInt((sums.sumsByType.testCreation?.sumTests / Object.keys(creationInsights).length).toFixed(1)) ?? 0,
    'Median Tests Created Per Week': computeDataMedian(creationInsights.map((m) => parseInt(m.total_steps))) || 0,
    'Max Tests Per Week': parseInt(maxTestPerWeek?.total_steps || '0') || 0,
    'Min Tests Per Week': parseInt(minTestsPerWeek?.total_steps || '0') || 0,
  };

  return teamInsights;
};

export const computeComparisonInsightsForCreationCompTable = (
  qaeInsights: CreationComparisonInsights,
  teamInsights: CreationComparisonInsights,
): Record<keyof CreationComparisonInsights, string | number> => {
  const comparisonInsights = {
    // QAEs percentage of teams total workflows created
    'Workflows Created': `${((qaeInsights['Workflows Created'] / teamInsights['Workflows Created']) * 100).toFixed(1)}%`,
    // QAEs percentage of teams total tests created
    'Tests Created': `${((qaeInsights['Tests Created'] / teamInsights['Tests Created']) * 100).toFixed(1)}%`,
    // QAEs percentage of teams total outlining tasks completed
    'Outlining Tasks Completed': `${((qaeInsights['Outlining Tasks Completed'] / teamInsights['Outlining Tasks Completed']) * 100).toFixed(1)}%`,
    // Difference between QAEs average tests created per week and teams
    'Average Tests Created Per Week': (+qaeInsights['Average Tests Created Per Week'] - +teamInsights['Average Tests Created Per Week']).toFixed(1),
    // Difference between QAEs median tests created per week and teams
    'Median Tests Created Per Week': (qaeInsights['Median Tests Created Per Week'] - teamInsights['Median Tests Created Per Week']).toFixed(1),
    // Difference between QAEs max tests per week and teams
    'Max Tests Per Week': (qaeInsights['Max Tests Per Week'] - teamInsights['Max Tests Per Week']).toFixed(1),
    // Difference between QAEs min tests per week and teams
    'Min Tests Per Week': (qaeInsights['Min Tests Per Week'] - teamInsights['Min Tests Per Week']).toFixed(1),
  };

  return comparisonInsights;
};

/**
 * Determines tailwind color class based on category and value.
 */
export const determineColorForCreationCompTable = (
  category: string,
  value: string | number,
  qae: User,
  team: QaTeam,
  teamSums: SumObject,
): string => {
  // return gray if qae is lead
  if (qae.isLead) return '';

  // get non-lead members & all positions
  const nonLeadTeamMembers = team.members.filter((member) => !member.isLead);
  const allPositions = nonLeadTeamMembers.map((member) => member.position).filter((position): position is string => position !== undefined);

  // get expected contributions for each position on the team
  const expectedContributions = getExpectedContributionsForTeam(allPositions);

  // get individual position and contribution
  const individualPosition = qae.position;
  const individualContribution = parseFloat(value.toString());

  // if value is a percent category, determine contribution % based on team size and return color class based on tolerance
  if (category === 'Workflows Created' || category === 'Tests Created' || category === 'Outlining Tasks Completed') {
    // 15% tolerance
    const tolerance = 0.15;
    const expectedContribution = expectedContributions[individualPosition] ?? 0;

    if (individualContribution >= expectedContribution) {
      // good if equal or greater than even contribution to the team
      return EXCEEDING_EXPECTATIONS;
    } else if (individualContribution >= expectedContribution * (1 - tolerance)) {
      // "okay" if within 15% of your EXPECTED contribution to the team
      return MEETING_EXPECTATIONS;
    } else {
      // bad if less than 15% of your EXPECTED contribution to the team
      return BELOW_EXPECTATIONS;
    }
  }

  // if value is an average/median, determine positive/negative threshold and return color class based on tolerance
  if (category === 'Average Tests Created Per Week' || category === 'Median Tests Created Per Week') {
    // determine tolerance based on total team tests created
    const tolerance = teamSums.sumTests * 0.15;

    if (individualContribution >= 0) {
      // good if positive
      return EXCEEDING_EXPECTATIONS;
    } else if (individualContribution >= -tolerance) {
      // "okay" if within 15% of the team average/median
      return MEETING_EXPECTATIONS;
    } else {
      // bad if less than 15% of the team average/median
      return BELOW_EXPECTATIONS;
    }
  }

  // if max tests, return good if 0, bad if negative
  if (category === 'Max Tests Per Week') {
    if (individualContribution >= 0) {
      return EXCEEDING_EXPECTATIONS;
    } else {
      return BELOW_EXPECTATIONS;
    }
  }

  // if min tests, return good if positive, bad if negative or 0 (it was you or a tie)
  if (category === 'Min Tests Per Week') {
    if (individualContribution > 0) {
      return EXCEEDING_EXPECTATIONS;
    } else {
      return BELOW_EXPECTATIONS;
    }
  }

  return '';
};
// #endregion

/*
------------------------------------ INVESTIGATION COMPARISON TABLE HELPERS ------------------------------------
*/
// #region investigation

export type InvestigationCompTableCategoryCategories =
  | 'Failures Investigated'
  | 'Average Failures Per Week'
  | 'Test Steps Investigated'
  | 'Average Test Steps Per Week'
  | 'Failures Passed on Retry'
  | 'Average Failures Passed on Retry Per Week'
  | 'Failures Passed with Fixes'
  | 'Average Failures Passed with Fixes Per Week'
  | 'Failures Investigated as Bug'
  | 'Average Failures Investigated as Bug Per Week'
  | 'Failures Investigated as Maintenance'
  | 'Average Failures Investigated as Maintenance Per Week'
  | 'Suites Investigated'
  | 'Average Suites Per Week'
  | 'Median Suites Per Week'
  | 'Max Suites Per Week'
  | 'Min Suites Per Week';

/**
 * Computes QAE investigation insights
 * @param {import('../types.js').QAEInvestigationDataObject} insights
 * @returns {Record<InvestigationCompTableCategoryCategories, number>}
 */
export const computeQAEInsightsForInvestigationCompTable = (
  insights: QAEInvestigationDataObject,
): Record<InvestigationCompTableCategoryCategories, number> => {
  // min/max
  const maxSuitesPerWeek = _.maxBy(_.values(insights?.weekly), 'numberOfSuitesInvestigated')?.numberOfSuitesInvestigated;
  const minSuitesPerWeek = _.minBy(_.values(insights?.weekly), 'numberOfSuitesInvestigated')?.numberOfSuitesInvestigated;

  // avg/median
  const medianSuitesPerWeek = computeMedian(_.map(insights.weekly, 'numberOfSuitesInvestigated')) || 0;
  const averageSuitesPerWeek = _.mean(_.map(insights.weekly, 'numberOfSuitesInvestigated')) || 0;
  const averageFailuresPerWeek = _.meanBy(_.map(insights.weekly, 'numberOfRunsInvestigated')) || 0;
  const averageTestsPerWeek = _.meanBy(_.map(insights.weekly, 'numberOfTestsInvestigated')) || 0;
  const averageFailuresPassedOnFlakePerWeek = _.meanBy(_.map(insights.weekly, 'numberOfRunsPassingOnFlake')) || 0;
  const averageFailuresPassedOnFixPerWeek = _.meanBy(_.map(insights.weekly, 'numberOfRunsPassingOnFix')) || 0;

  const averageFailuresInvestigatedAsBugPerWeek = _.meanBy(_.map(insights.weekly, 'numberOfRunsReportedAsBugs')) || 0;

  const averageFailuresInvestigatedAsMaintPerWeek = _.meanBy(_.map(insights.weekly, 'numberOfRunsReportedAsMaintenance')) || 0;

  return {
    'Failures Investigated': insights.totals.numberOfRunsInvestigated || 0,
    'Average Failures Per Week': roundToTwo(averageFailuresPerWeek) || 0,
    'Test Steps Investigated': insights.totals.numberOfTestsInvestigated || 0,
    'Average Test Steps Per Week': roundToTwo(averageTestsPerWeek) || 0,
    'Failures Passed on Retry': insights.totals.numberOfRunsPassingOnFlake || 0,
    'Average Failures Passed on Retry Per Week': roundToTwo(averageFailuresPassedOnFlakePerWeek) || 0,
    'Failures Passed with Fixes': insights.totals.numberOfRunsPassingOnFix || 0,
    'Average Failures Passed with Fixes Per Week': roundToTwo(averageFailuresPassedOnFixPerWeek) || 0,
    'Failures Investigated as Bug': insights.totals.numberOfRunsReportedAsBugs || 0,
    'Average Failures Investigated as Bug Per Week': roundToTwo(averageFailuresInvestigatedAsBugPerWeek) || 0,
    'Failures Investigated as Maintenance': insights.totals.numberOfRunsReportedAsMaintenance || 0,
    'Average Failures Investigated as Maintenance Per Week': roundToTwo(averageFailuresInvestigatedAsMaintPerWeek) || 0,
    'Suites Investigated': insights.totals.numberOfSuitesInvestigated || 0,
    'Average Suites Per Week': roundToTwo(averageSuitesPerWeek) || 0,
    'Median Suites Per Week': roundToTwo(medianSuitesPerWeek) || 0,
    'Max Suites Per Week': maxSuitesPerWeek || 0,
    'Min Suites Per Week': minSuitesPerWeek || 0,
  };
};

export const computeTeamInsightsForInvestigationCompTable = (insights: {
  weekly: Record<string, TeamInvestigationData>;
  totals: TeamInvestigationData;
}): Record<InvestigationCompTableCategoryCategories, number> => {
  // min/max
  const maxSuitesPerWeek = _.max(_.map(insights.weekly, 'teamMaxSuitesInvestigated'));
  const minSuitesPerWeek = _.min(_.map(insights.weekly, 'teamMinSuitesInvestigated'));

  // avg/median
  const medianSuitesPerWeek = computeMedian(_.map(insights.weekly, 'teamMedianSuitesInvestigated')) || 0;
  const averageSuitesPerWeek = _.mean(_.map(insights.weekly, 'teamAverageSuitesInvestigated')) || 0;

  const averageFailuresPerWeekPerQAE = _.mean(_.map(insights.weekly, 'teamAverageRunsInvestigated'));

  const averageTestsPerWeekPerQAE = _.mean(_.map(insights.weekly, 'teamAverageTestsInvestigated'));

  const averageFailuresPassedOnFlakePerWeekPerQAE = _.mean(_.map(insights.weekly, 'teamAverageRunsPassingOnFlake'));

  const averageFailuresPassedOnFixPerWeekPerQAE = _.mean(_.map(insights.weekly, 'teamAverageRunsPassingOnFix'));

  const averageFailuresInvestigatedAsBugPerWeekPerQAE = _.mean(_.map(insights.weekly, 'teamAverageRunsReportedAsBugs'));

  const averageFailuresInvestigatedAsMaintPerWeekPerQAE = _.mean(_.map(insights.weekly, 'teamAverageRunsReportedAsMaintenance'));

  return {
    'Failures Investigated': insights.totals.teamNumberOfRunsInvestigated || 0,
    'Average Failures Per Week': roundToTwo(averageFailuresPerWeekPerQAE) || 0,
    'Test Steps Investigated': insights.totals.teamNumberOfTestsInvestigated || 0,
    'Average Test Steps Per Week': roundToTwo(averageTestsPerWeekPerQAE) || 0,
    'Failures Passed on Retry': insights.totals.teamNumberOfRunsPassingOnFlake || 0,
    'Average Failures Passed on Retry Per Week': roundToTwo(averageFailuresPassedOnFlakePerWeekPerQAE) || 0,
    'Failures Passed with Fixes': insights.totals.teamNumberOfRunsPassingOnFix || 0,
    'Average Failures Passed with Fixes Per Week': roundToTwo(averageFailuresPassedOnFixPerWeekPerQAE) || 0,
    'Failures Investigated as Bug': insights.totals.teamNumberOfRunsReportedAsBugs || 0,
    'Average Failures Investigated as Bug Per Week': roundToTwo(averageFailuresInvestigatedAsBugPerWeekPerQAE) || 0,
    'Failures Investigated as Maintenance': insights.totals.teamNumberOfRunsReportedAsMaintenance || 0,
    'Average Failures Investigated as Maintenance Per Week': roundToTwo(averageFailuresInvestigatedAsMaintPerWeekPerQAE) || 0,
    'Suites Investigated': insights.totals.teamNumberOfSuitesInvestigated || 0,
    'Average Suites Per Week': roundToTwo(averageSuitesPerWeek) || 0,
    'Median Suites Per Week': roundToTwo(medianSuitesPerWeek) || 0,
    'Max Suites Per Week': maxSuitesPerWeek || 0,
    'Min Suites Per Week': minSuitesPerWeek || 0,
  };
};

type ComparisonInsights = Record<InvestigationCompTableCategoryCategories, string | number>;

export const computeComparisonInsightsForInvestigationCompTable = (
  qaeInsights: Record<InvestigationCompTableCategoryCategories, number>,
  teamInsights: Record<InvestigationCompTableCategoryCategories, number>,
): ComparisonInsights => {
  const getPercentage = (num: number, den: number): string => {
    const result = num / den;
    return isNaN(result) ? '0%' : `${roundToTwo(result * 100)}%`;
  };

  const getDiff = (qaeVal: number, teamVal: number): number => {
    const result = qaeVal - teamVal;
    return isNaN(result) ? 0 : roundToTwo(result);
  };

  return {
    // QAEs percentage of team's failures investigated
    'Failures Investigated': getPercentage(qaeInsights['Failures Investigated'], teamInsights['Failures Investigated']),

    // Difference between QAEs average failures per week and team's average failures per week
    'Average Failures Per Week': getDiff(qaeInsights['Average Failures Per Week'], teamInsights['Average Failures Per Week']),

    // QAEs percentage of team's test steps investigated
    'Test Steps Investigated': getPercentage(qaeInsights['Test Steps Investigated'], teamInsights['Test Steps Investigated']),

    // Difference between QAEs average test steps per week and team's average test steps per week
    'Average Test Steps Per Week': getDiff(qaeInsights['Average Test Steps Per Week'], teamInsights['Average Test Steps Per Week']),

    // QAEs percentage of team's failures passed on flake
    'Failures Passed on Retry': getPercentage(qaeInsights['Failures Passed on Retry'], teamInsights['Failures Passed on Retry']),

    // Difference between QAEs average failures passed on flake per week and team's average failures passed on flake per week
    'Average Failures Passed on Retry Per Week': getDiff(
      qaeInsights['Average Failures Passed on Retry Per Week'],
      teamInsights['Average Failures Passed on Retry Per Week'],
    ),

    // QAEs percentage of team's failures passed on fix
    'Failures Passed with Fixes': getPercentage(qaeInsights['Failures Passed with Fixes'], teamInsights['Failures Passed with Fixes']),

    // Difference between QAEs average failures passed on fix per week and team's average failures passed on fix per week
    'Average Failures Passed with Fixes Per Week': getDiff(
      qaeInsights['Average Failures Passed with Fixes Per Week'],
      teamInsights['Average Failures Passed with Fixes Per Week'],
    ),

    // QAEs percentage of team's failures investigated as bug
    'Failures Investigated as Bug': getPercentage(qaeInsights['Failures Investigated as Bug'], teamInsights['Failures Investigated as Bug']),

    // Difference between QAEs average failures investigated as bug per week and team's average failures investigated as bug per week
    'Average Failures Investigated as Bug Per Week': getDiff(
      qaeInsights['Average Failures Investigated as Bug Per Week'],
      teamInsights['Average Failures Investigated as Bug Per Week'],
    ),

    // QAEs percentage of team's failures investigated as maintenance
    'Failures Investigated as Maintenance': getPercentage(
      qaeInsights['Failures Investigated as Maintenance'],
      teamInsights['Failures Investigated as Maintenance'],
    ),

    // Difference between QAEs average failures investigated as maintenance per week and team's average failures investigated as maintenance per week
    'Average Failures Investigated as Maintenance Per Week': getDiff(
      qaeInsights['Average Failures Investigated as Maintenance Per Week'],
      teamInsights['Average Failures Investigated as Maintenance Per Week'],
    ),

    // QAEs percentage of team's suites investigated
    'Suites Investigated': getPercentage(qaeInsights['Suites Investigated'], teamInsights['Suites Investigated']),

    // Difference between QAEs average suites per week and team's average suites per week
    'Average Suites Per Week': getDiff(qaeInsights['Average Suites Per Week'], teamInsights['Average Suites Per Week']),

    // Difference between QAEs median suites per week and team's median suites per week
    'Median Suites Per Week': getDiff(qaeInsights['Median Suites Per Week'], teamInsights['Median Suites Per Week']),

    // Difference between QAEs max suites per week and team's max suites per week
    'Max Suites Per Week': getDiff(qaeInsights['Max Suites Per Week'], teamInsights['Max Suites Per Week']),

    // Difference between QAEs min suites per week and team's min suites per week
    'Min Suites Per Week': getDiff(qaeInsights['Min Suites Per Week'], teamInsights['Min Suites Per Week']),
  };
};

/**
 * Determines tailwind color class based on category and value.
 */
export const determineColorForInvestigationCompTable = (
  category: InvestigationCompTableCategoryCategories,
  value: string | number,
  qae: User,
  team: QaTeam,
  teamSums: Record<InvestigationCompTableCategoryCategories, number>,
) => {
  // return gray if qae is lead
  if (qae.isLead) return 'text-gray-400';

  // get non-lead members & all positions
  const nonLeadTeamMembers = team.members.filter((m) => !m.isLead);
  const allPositions = nonLeadTeamMembers.map((member) => member.position).filter((position): position is string => position !== undefined);

  // get expected contributions for each position on the team
  const expectedContributions = getExpectedContributionsForTeam(allPositions);

  // get individual position and contribution
  const individualPosition = qae.position;
  let individualContribution = parseFloat(value.toString());

  // determine if category is for maintenance
  const isMaintenance = category.includes('Maintenance');

  if (category.includes('Average') || category.includes('Median')) {
    // handle opposite expectation for maintenance
    if (isMaintenance) {
      individualContribution = -individualContribution;
    }

    // determine tolerance based on total team tests created
    const tolerance = teamSums[category] * 0.15;

    if (individualContribution >= 0) {
      // good if positive
      return EXCEEDING_EXPECTATIONS;
    } else if (individualContribution >= -tolerance) {
      // "okay" if within 15% of the team average/median
      return MEETING_EXPECTATIONS;
    } else {
      // bad if less than 15% of the team average/median
      return BELOW_EXPECTATIONS;
    }
  } else if (category === 'Max Suites Per Week') {
    return individualContribution >= 0 ? EXCEEDING_EXPECTATIONS : BELOW_EXPECTATIONS;
  } else if (category === 'Min Suites Per Week') {
    return individualContribution > 0 ? EXCEEDING_EXPECTATIONS : BELOW_EXPECTATIONS;
  } else {
    // 15% tolerance
    const tolerance = 0.15;
    const expectedContribution = expectedContributions[individualPosition] ?? 0;

    if (isMaintenance) {
      if (individualContribution <= expectedContribution) {
        // good if equal or greater than even contribution to the team
        return EXCEEDING_EXPECTATIONS;
      } else if (individualContribution <= expectedContribution * (1 - tolerance)) {
        // "okay" if within 15% of your EXPECTED contribution to the team
        return MEETING_EXPECTATIONS;
      } else {
        // bad if less than 15% of your EXPECTED contribution to the team
        return BELOW_EXPECTATIONS;
      }
    } else {
      if (individualContribution >= expectedContribution) {
        // good if equal or greater than even contribution to the team
        return EXCEEDING_EXPECTATIONS;
      } else if (individualContribution >= expectedContribution * (1 - tolerance)) {
        // "okay" if within 15% of your EXPECTED contribution to the team
        return MEETING_EXPECTATIONS;
      } else {
        // bad if less than 15% of your EXPECTED contribution to the team
        return BELOW_EXPECTATIONS;
      }
    }
  }
};
// #endregion
