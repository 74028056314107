import { Box, MenuItem } from '@mui/material';
import { MaterialReactTable, useMaterialReactTable } from "material-react-table";
import { useMemo, useState } from "react";
import { useQueryClient } from '@tanstack/react-query';

// Component and variable imports
import FinalRoundDetailPanel from './FinalRoundDetailPanel';
import { statusMap } from '../helpers/constants';
import EditableCell from '../helpers/EditableCell';
import { handleSubmissionChange, handleCandidateInfoChange } from '../helpers/hiringHelpers';
import { inviteExecsToFinalRoundTeam } from '../api';

// Date and time picker imports
import dayjs from 'dayjs';
import { DateTimePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import Toast from '../../Layout/Toast';


function FinalRoundTable({ data }) {
  // const [open, setOpen] = useState(false);
  // const [selectedFinalRoundRowData, setSelectedFinalRoundRowData] = useState({});
  const [toast, setToast] = useState(<></>);
  // const [isLoadingSuperday, setIsLoadingSuperday] = useState(false);
  // const [editing, setEditing] = useState(false);

  const queryClient = useQueryClient();

  const [value, setValue] = useState(() =>
    data.reduce((acc, row) => {
      acc[row.id] = row || '';
      return acc;
    }, {}),
  );

  const handleInviteExecToFinalRoundTeam = async (row) => {
    const { original: { finalRoundTeamId } } = row;

    let result = await inviteExecsToFinalRoundTeam({ teamId: finalRoundTeamId });
    let { success } = result;

    if (!success) {
      console.log(result);
    }

    let title = success ? 'Success!' : 'Ruh-roh';
    let message = success ?
      `Invitations to ${row.original.candidate.firstName}'s Final Round Team have been sent.`
      : 'Something went wrong!';

    setToast(<Toast title={title} message={message} isSuccess={success} />);
  };

  // const handleInitFinalRound = async (superday) => {
  //   setIsLoadingSuperday(true);

  //   const candidateData = {
  //     superday,
  //     candidateEmail: selectedFinalRoundRowData.original.candidate.email,
  //     candidateName: selectedFinalRoundRowData.original.candidate.firstName,
  //     submissionId: selectedFinalRoundRowData.original.id,
  //   };

  //   let result = await initCandidateFinalRound(candidateData);
  //   let { success } = result;

  //   if (!success) {
  //     console.log(result);
  //   }

  //   let title = success ? 'Success!' : 'Ruh-roh';
  //   let message = success ? 'Candidate superday created' : 'Something went wrong';

  //   setIsLoadingSuperday(false);
  //   setOpen(false);
  //   setToast(<Toast title={title} message={message} key={new Date().toISOString()} isSuccess={success} />);
  // };


  const columns = useMemo(() => {
    return [
      // ========================= CANDIDATE NAME =========================
      {
        header: 'Candidate Name',
        accessorKey: 'candidateName',
        accessorFn: (row) => {
          return ({
            candidate: row.candidate,
            firstName: row.candidate.firstName,
            lastName: row.candidate.lastName,
          });
        },
        Cell: ({ cell }) => {
          const { candidate, firstName, lastName } = cell.getValue();
          const initialValue = `${firstName} ${lastName}`;

          // Handle saving the new name
          const handleSave = (newValue) => {
            const [newFirstName, newLastName] = newValue.split(/\s(.+)/);
            handleCandidateInfoChange(
              candidate.id,
              {
                firstName: newFirstName || '',
                lastName: newLastName || '',
              },
              setValue,
              queryClient,
            );
          };

          return (
            <EditableCell
              initialValue={initialValue}
              onSave={handleSave}
              placeholder="Candidate Name"
            />
          );
        },
      },
      // ========================= CANDIDATE EMAIL =========================
      {
        accessorFn: (row) => row?.candidate.email,
        id: 'email',
        header: 'Candidate Email',
        enableClickToCopy: true,
        Cell: ({ cell }) => {
          const candidate = cell.row.original.candidate;
          const initialValue = candidate.email || '';

          // Handle saving the new email
          const handleSave = (newValue) => {
            handleCandidateInfoChange(
              candidate.id,
              {
                email: newValue,
              },
              setValue,
              queryClient,
            );
          };

          return (
            <EditableCell
              initialValue={initialValue}
              onSave={handleSave}
              placeholder="Email"
            />
          );
        },
      },
      // ========================= INTERVIEW STATUS =========================
      {
        accessorFn: (row) => ({
          status: row.status,
          submissionId: row,
        }), //accessorFn used to join multiple data into a single cell
        id: 'statusId', //id is still required when using accessorFn instead of accessorKey
        header: 'Status',

        Cell: ({ cell }) => {
          const { submissionId, status } = cell.getValue();

          const matchedStatus = statusMap.find((s) => s.value === status);

          return (
            <>
              <select
                id="status"
                name="status"
                style={{
                  backgroundColor: matchedStatus?.color || 'blue',
                  color: matchedStatus?.textColor || 'white',
                  textAlign: 'center',
                  borderRadius: '20px',
                  padding: '5px 10px',
                  width: '100%',
                  overflow: 'clip',
                  fontSize: '14px',
                }}
                value={status}
                onChange={(e) => handleSubmissionChange(
                  submissionId.id,
                  { status: e.target.value },
                  setValue,
                  queryClient,
                )}
              >
                {statusMap.map((status) => (
                  <option value={status.value} key={status.value}>
                    {status.label}
                  </option>
                ))}
              </select>
            </>
          );
        },
      },
      // ========================= FINAL ROUND DATE =========================
      {
        accessorFn: (row) => ({
          submissionId: row,
          finalRoundDate: row.finalRoundDate,
        }),
        id: 'finalRoundDate',
        header: 'Final Round Date',
        Cell: ({ cell }) => {
          const { submissionId, finalRoundDate } = cell.getValue();
          // const formattedDate = finalRoundDate ? (dayjs(finalRoundDate)).format('MMM DD, YYYY HH:mma') : '';

          return (
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
              }}
            >
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DateTimePicker
                  value={dayjs(finalRoundDate)}
                  onChange={(newValue) => handleSubmissionChange(
                    submissionId.id,
                    { finalRoundDate: newValue },
                    setValue,
                    queryClient,
                  )}
                />
              </LocalizationProvider>

            </Box>
          );
        },
      },
      // ========================= CANDIDATE LOCATION =========================
      {
        accessorFn: (row) => row.candidate.location ? row.candidate.location : "N/A",
        id: 'location',
        header: 'Location',
        Cell: ({ cell }) => {
          const candidate = cell.row.original.candidate;
          const initialValue = candidate.location || '';

          // Handle saving the new location
          const handleSave = (newValue) => {
            handleCandidateInfoChange(
              candidate.id,
              {
                location: newValue,
              },
              setValue,
              queryClient,
            );
          };

          return (
            <EditableCell
              initialValue={initialValue}
              onSave={handleSave}
              placeholder="Location"
            />
          );
        },
      },
      // ========================= FINAL ROUND SANDBOX =========================
      {
        header: 'Final Round Team',
        accessorKey: 'finalRoundTeam',
        Cell: ({ renderedCellValue, row }) => (
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
            }}
          >
            {renderedCellValue &&
              <a
                href={`https://app.qawolf.com/${renderedCellValue}`}
                target='_blank'
                rel='noreferrer'
                style={{
                  color: '#6366f1',
                }}
              >
                {row.original.candidate.firstName}'s Superday
              </a>
            }
            {/* {!renderedCellValue &&
              <button
                onClick={() => setOpen(true)}
              >
                Create Final Round Superday
              </button>
            } */}
            {/* {open && selectedFinalRoundRowData ?
              <Dialog open={open} onClose={() => { setOpen(false); }}>
                <DialogTitle>Which Superday for {selectedFinalRoundRowData?.original?.candidate.firstName}</DialogTitle>
                <DialogContent>
                  <div className='flex w-full justify-center align-center'>
                    <Button onClick={() => handleInitFinalRound("superday1")}>Superday 1</Button>
                    <Button onClick={() => handleInitFinalRound("superday2")}>Superday 2</Button>
                    <Button onClick={() => handleInitFinalRound("superday3")}>Superday 3</Button>
                  </div>

                  {isLoadingSuperday &&
                    <div className='flex w-full justify-center align-center'>
                      <CircularProgress />
                    </div>
                  }
                </DialogContent>
              </Dialog> : null
            } */}
          </Box>
        ),
      },
    ];
  }, [data, value]);

  const table = useMaterialReactTable({
    columns,
    data,
    initialState: {
      pagination: {
        pageIndex: 0,
        pageSize: 100,
      },
      columnPinning: {
        left: ['mrt-row-select', 'mrt-row-expand'],
        right: ['mrt-row-actions'],
      },
    },
    enableColumnActions: true,
    enableColumnFilters: true,
    enableTopToolbar: true,
    enableTableFooter: true,
    enableRowActions: true,
    autoResetPageIndex: false,
    enableExpanding: true,

    muiExpandButtonProps: ({ row, table }) => ({
      onClick: () => table.setExpanded({ [row.id]: !row.getIsExpanded() }),
    }),

    renderDetailPanel: ({ row }) => <FinalRoundDetailPanel row={row} data={data} queryClient={queryClient} />,

    // NOTE: Pass 'row' back in here when 335 and 336 are back
    renderRowActionMenuItems: ({ closeMenu, row }) => [
      <MenuItem
        key={0}
        sx={{ m: 0 }}
        onClick={() => {
          handleInviteExecToFinalRoundTeam(row);
          closeMenu();
        }}>
        Invite Eric/Laura to Final Round Team
      </MenuItem>,
    ],
  });

  return (
    <div>
      <MaterialReactTable table={table} />
      {toast}
    </div>
  );
}

export default FinalRoundTable;
