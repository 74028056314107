import { SyntheticEvent, useEffect, useState } from 'react';
import { Box, Button, createTheme, Snackbar, Tab, Tabs, ThemeProvider, Tooltip } from '@mui/material';
import { Close, HomeRounded, RestartAltSharp } from '@mui/icons-material';
import { useMutation, useQuery } from '@tanstack/react-query';
import { sendGetRequest, sendPostRequest, sendPutRequest } from '../../../../utils/tanstackNetwork';
import SaveViewModal from '../../../Tasks/SaveViewModal';
import { MRT_TableInstance } from 'material-react-table';
import { ClientSummaryTableRow, SavedTableView, User } from '../types';

const theme = createTheme({
  components: {
    MuiTab: {
      styleOverrides: {
        root: {
          minHeight: '48px',
        },
      },
    },
  },
});

export default function ClientSummaryTableToolBar({ table }: { table: MRT_TableInstance<ClientSummaryTableRow> }) {
  const user: User = JSON.parse(localStorage.getItem('user') as string);
  const selectedViewId = +(localStorage.getItem('clientSummaryTableViewId') || '0');

  const [selectedView, setSelectedView] = useState({ id: 0, state: {} });
  const [isSaveEnabled, setIsSaveEnabled] = useState(selectedView.id === 0);
  const [showSaveModal, setShowSaveModal] = useState(false);

  const [newViewName, setNewViewName] = useState('');

  // Show a success message when a view is updated
  const [showResMsg, setShowResMsg] = useState(false);
  const [resMsg, setResMsg] = useState('');

  const { data: savedViews, refetch } = useQuery<SavedTableView[]>({
    queryKey: ['clientSummarySavedViews'],
    queryFn: () => sendGetRequest(`/table-views/${user.qawId}?tableName=clientSummaryTable`),
    select: (data) => data?.filter((v) => v.id) || [],
    placeholderData: [],
    refetchOnWindowFocus: false,
  });

  // Set the selected view to the view in local storage
  useEffect(() => {
    // Exit early if there are no saved views or the selected view id is not set
    if (!savedViews || !selectedViewId) return;

    // Find the saved view that matches the selected view id from local storage
    const storedSavedView = savedViews.find((v) => v.id === selectedViewId);

    if (storedSavedView) {
      // Update the selected view and disable the save button
      setSelectedView(storedSavedView);
      setIsSaveEnabled(false);

      // Apply the selected view state
      table.setState((prev) => ({ ...prev, ...storedSavedView.state }));
    }
  }, [savedViews]);

  const saveViewMutation = useMutation({
    mutationFn: () =>
      sendPostRequest('/save-table-view', { userQawId: user.qawId, name: newViewName, state: table.getState(), tableName: 'clientSummaryTable' }),
    onSuccess: (data) => {
      refetch();
      setSelectedView(data);
      setNewViewName('');
      setIsSaveEnabled(false);
      setShowSaveModal(false);
      localStorage.setItem('clientSummaryTableViewId', data.id.toString());
    },
  });

  const updateViewMutation = useMutation({
    mutationFn: () =>
      sendPutRequest('/update-table-view', {
        id: selectedView.id,
        state: table.getState(),
      }),
    onSuccess: (data) => {
      refetch();
      setResMsg('View updated successfully');
      setShowResMsg(true);
      localStorage.setItem('clientSummaryTableViewId', data.id.toString());
    },
  });

  const deleteViewMutation = useMutation({
    mutationFn: () =>
      sendPostRequest('/delete-table-view', {
        viewId: selectedView.id,
      }),
    onSuccess: () => {
      // Reset the selected view to the home/default view
      setSelectedView({ id: 0, state: {} });
      setIsSaveEnabled(true);
      refetch();
    },
  });

  const handleSelectViewClick = (_: SyntheticEvent, val: number) => {
    const savedView = savedViews?.find((v) => v.id === val) || { id: 0, state: {} };

    // Update the selected view
    setSelectedView((prev) => ({ ...prev, ...savedView }));

    // Handle selecting the home/default savedView
    if (savedView.id === 0) {
      // Enable the save view button and disable the update view button
      return setIsSaveEnabled(true);
    }

    // Disable the save view button and enable the update view button
    setIsSaveEnabled(false);

    // Store the selected view id in local storage
    localStorage.setItem('clientSummaryTableViewId', savedView.id.toString());

    // Apply the selected view state
    return table.setState((prev) => ({ ...prev, ...savedView.state }));
  };

  const handleDeleteView = () => {
    if (!selectedView.id) return;
    deleteViewMutation.mutate();
  };

  return (
    <ThemeProvider theme={theme}>
      <Box
        sx={{
          display: 'flex',
          gap: '0.5rem',
          p: '8px',
          justifyContent: 'space-between',
        }}
      >
        <Box sx={{ overflowX: 'auto', maxWidth: '70%' }}>
          <Tabs value={selectedView.id} onChange={handleSelectViewClick} variant="scrollable" scrollButtons="auto">
            {/* Home/Default view */}
            <Tab value={0} icon={<HomeRounded />} disableFocusRipple disableRipple wrapped />

            {/* Saved Views */}
            {savedViews?.map((view) => {
              const isSelected = view.id === selectedView.id;
              return (
                <Tab
                  value={view.id}
                  key={view.id}
                  icon={
                    isSelected && view.id !== 0 ? (
                      <Close
                        onClick={handleDeleteView}
                        sx={{
                          fontSize: '18px',
                          '&:hover': {
                            color: 'black',
                          },
                        }}
                      />
                    ) : (
                      <></>
                    )
                  }
                  iconPosition="end"
                  label={view.name}
                  disableFocusRipple
                  disableRipple
                  wrapped
                />
              );
            })}
          </Tabs>
        </Box>
        <Box>
          <Box sx={{ display: 'flex', gap: '0.5rem', width: '100%', minWidth: 'fit-content' }}>
            {/* State Reset Button */}
            {
              <Tooltip title="Restore default settings" onClick={() => table.reset()}>
                <Button variant="contained">
                  <RestartAltSharp />
                </Button>
              </Tooltip>
            }

            {/* Save Button */}
            {
              <Button variant="contained" disabled={!isSaveEnabled} onClick={() => setShowSaveModal(true)}>
                Save View
              </Button>
            }

            {/* Update Button */}
            {
              <Button variant="contained" disabled={isSaveEnabled} onClick={() => updateViewMutation.mutate()}>
                Update View
              </Button>
            }
          </Box>
        </Box>
        <SaveViewModal
          open={showSaveModal}
          setOpen={setShowSaveModal}
          mutateTableView={saveViewMutation}
          name={newViewName}
          setName={setNewViewName}
        />
        <Snackbar
          open={showResMsg}
          ContentProps={{ sx: { bgcolor: 'white', color: 'black' } }}
          autoHideDuration={3000}
          onClose={() => setShowResMsg(false)}
          message={resMsg}
          anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        />
      </Box>
    </ThemeProvider>
  );
}
