import { useEffect, useState } from 'react';
import TasksTable from '../../shared/TasksTable';
import { useParams } from 'react-router-dom';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { useTaskFilterCriteria } from '../../context/TaskFilterCriteriaContext';
import { getAllTasks } from '../../Tasks/api';
import { flattenTaskData } from '../../Tasks/helpers.js';

export default function ClientTasksTable({ tableState, setTableState, defaultTableState }) {
  const [toast, setToast] = useState(<></>);
  const { filterCriteria, updateFilterCriteria } = useTaskFilterCriteria();

  // Reset filter criteria when component unmounts
  useEffect(() => {
    return () => {
      updateFilterCriteria([]);
    };
  }, []);

  const { teamId } = useParams();

  // Get tasks for specific client
  const {
    data: clientTasks,
    isFetching,
    isLoading,
    isError,
    error,
  } = useQuery({
    queryKey: ['allTasks', teamId],
    queryFn: ({ queryKey }) => getAllTasks(queryKey[1]), // queryKey[1] is the teamId var
    placeholderData: [],
    select: (data) => flattenTaskData(data, tableState.groupCRandMRs),
  });

  const queryClient = useQueryClient();

  if (isFetching || isLoading) return <div className="rounded-xl border border-gray-200 bg-white mx-2 mb-4">{'Loading client tasks...'}</div>;
  if (isError)
    return (
      <div className="rounded-xl border border-gray-200 bg-white mx-2 mb-4">
        <h1>{'Error loading client tasks...'}</h1>
        <p>{error.message}</p>
      </div>
    );

  return (
    <>
      <TasksTable
        data={clientTasks}
        initialInstanceState={{
          columnVisibility: {
            customer: false,
            name: true,
            progress: false,
            workflowStatus: false,
            age: false,
            steps: false,
            type: true,
          },
        }}
        setToast={setToast}
        showSkeletons={false}
        showProgressBars={false}
        filterCriteria={filterCriteria}
        tableState={tableState}
        setTableState={setTableState}
        saveViewButton={undefined}
        clearSelectedView={undefined}
        selectedView={undefined}
        defaultTableState={defaultTableState}
        queryClient={queryClient}
      />
      {toast}
    </>
  );
}
