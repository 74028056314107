import React, { useEffect, useState } from 'react';
import { useMutation } from '@tanstack/react-query';
import { sendPostRequest } from '../../utils/network';
import { SuiteNetworkReportResponse, AllTracesItem, OperationSummaryItem } from './typesForSuiteNetworkReport';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer, Cell } from 'recharts';
import WolfLoader from '../WolfLoader/WolfLoader';

export default function SuiteNetworkReport() {
  const [suiteId, setSuiteId] = useState('');
  const [suiteURL, setSuiteURL] = useState('');
  const [displayData, setDisplayData] = useState<AllTracesItem[] | undefined>();
  const [requestDuration, setRequestDuration] = useState<number | null>(null);
  const [operationSummary, setOperationSummary] = useState<OperationSummaryItem[]>();
  const [elapsedTime, setElapsedTime] = useState(0);
  const [isChartLoading, setIsChartLoading] = useState(true);

  // Mutation to get the suite network report from the endpoint
  const { mutate, status, error } = useMutation<SuiteNetworkReportResponse, Error, string>({
    mutationFn: async (suiteId: string) => {
      const response = await sendPostRequest('/suite-network-report', { suiteId });
      if (response && response.success) {
        return response.data as SuiteNetworkReportResponse;
      } else {
        throw new Error(`Failed to load report`);
      }
    },
    onSuccess: (data) => {
      setDisplayData(data.reportData.report.allTraces); // Update displayData with the response data
      setRequestDuration(data.durationInMs); // Update duration state
      setSuiteURL(data.reportData.report.suiteURL); // Update suite URL state
      setOperationSummary(data.reportData.report.operationSummary || []); // Update operationSummary state
    },
    onError: (error) => {
      console.error('Mutation error:', error);
    },
  });

  // Timer to update the elapsed time
  useEffect(() => {
    let timer: ReturnType<typeof setInterval>;
    if (status === 'pending') {
      timer = setInterval(() => {
        setElapsedTime((prev) => prev + 1);
      }, 1000);
    } else {
      setElapsedTime(0);
    }

    return () => clearInterval(timer);
  }, [status]);

  // Timer to measure the chart render time
  useEffect(() => {
    if (displayData) {
      setIsChartLoading(true);

      requestAnimationFrame(() => {
        performance.mark('chart-render-start');

        const timer = setTimeout(() => {
          performance.mark('chart-render-end');
          try {
            performance.measure('chart-render', 'chart-render-start', 'chart-render-end');
          } catch (error) {
            console.error('Performance measure error:', error);
          }

          performance.clearMarks('chart-render-start');
          performance.clearMarks('chart-render-end');
          performance.clearMeasures('chart-render');

          setIsChartLoading(false);
        }, 2000);

        return () => clearTimeout(timer);
      });
    }
  }, [displayData]);

  // Function to format the elapsed time
  const formatElapsedTime = (seconds: number) => {
    const mins = Math.floor(seconds / 60);
    const secs = seconds % 60;
    return `${mins} min ${secs < 10 ? '0' : ''}${secs} sec`;
  };

  // Function to handle the input change
  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSuiteId(event.target.value);
  };

  // Function to handle the form submission
  const handleSubmit = (event: React.FormEvent) => {
    event.preventDefault();
    mutate(suiteId);
  };

  // Calculate the domain for the XAxis as elapsed time in seconds
  const startTime = Math.min(...(displayData || []).map((d) => new Date(d.startedDateTime).getTime()));

  // Transform the data to include the start and end positions for each bar
  const transformedData = (displayData || [])
    .map((d) => ({
      ...d,
      duration: [new Date(d.startedDateTime).getTime() - startTime, d.endTime ? new Date(d.endTime).getTime() - startTime : undefined].filter(
        (time) => time !== undefined,
      ), // Calculate duration, exclude undefined
    }))
    .sort((a, b) => new Date(b.startedDateTime).getTime() - new Date(a.startedDateTime).getTime()); // Sort from latest to oldest

  // Calculate the oldest startedDateTime
  const oldestStartDateTime = new Date(Math.min(...(displayData || []).map((d) => new Date(d.startedDateTime).getTime()))).toLocaleString(undefined, {
    timeZoneName: 'short',
  });

  const sortedOperationSummary = [...(operationSummary || [])].sort((a, b) => b.failures - a.failures);

  return (
    <div>
      <div className="p-4">
        <h1 className="mb-4 text-2xl font-bold">Suite Network Report</h1>
        <form onSubmit={handleSubmit} className="flex flex-col gap-2" style={{ width: '20%' }}>
          <input type="text" value={suiteId} onChange={handleInputChange} placeholder="Enter Suite ID" />
          <button type="submit" className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded">
            Submit
          </button>
        </form>
      </div>
      {status === 'pending' && (
        <>
          <div className="p-10">
            <WolfLoader customStyles={{ height: '50px', width: '100px' }} customText="" />
          </div>
          <div className="text-blue-500 font-bold ml-4">
            Generating report for Suite ID: {suiteId}. This usually takes about 3-5 minutes.
            <br />
            <p className="text-gray-500">Elapsed Time: {formatElapsedTime(elapsedTime)}</p>
          </div>
        </>
      )}
      {status === 'success' && displayData && (
        <div className="ml-4 mb-1 inline-block border border-black p-2 max-w-fit">
          <div className="flex flex-col gap-2">
            <a
              href={process.env.PUBLIC_URL + '/flow-explorer/' + suiteId}
              className="text-blue-500 hover:underline flex items-center"
              target="_blank"
              rel="noopener noreferrer"
            >
              Open this suite in Flow Explorer
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className="w-6 h-6 ml-2"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M13.5 6H5.25A2.25 2.25 0 0 0 3 8.25v10.5A2.25 2.25 0 0 0 5.25 21h10.5A2.25 2.25 0 0 0 18 18.75V10.5m-10.5 6L21 3m0 0h-5.25M21 3v5.25"
                />
              </svg>
            </a>
            <a href={suiteURL} className="text-blue-500 hover:underline flex items-center" target="_blank" rel="noopener noreferrer">
              Open this suite in QA Wolf
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className="w-6 h-6 ml-2"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M13.5 6H5.25A2.25 2.25 0 0 0 3 8.25v10.5A2.25 2.25 0 0 0 5.25 21h10.5A2.25 2.25 0 0 0 18 18.75V10.5m-10.5 6L21 3m0 0h-5.25M21 3v5.25"
                />
              </svg>
            </a>
          </div>
        </div>
      )}
      {error && <div>Error loading environment load report: {error.message}</div>}
      {!displayData && status !== 'pending' && !error && (
        <div className="text-gray-500 font-bold m-5">No data available. Please enter a Suite ID and submit to load the report.</div>
      )}

      {status !== 'pending' && displayData && !error && (
        <>
          <p className="mb-1 ml-4 text-gray-500">Start Time Of First Operation: {oldestStartDateTime}</p>
          {requestDuration !== null && (
            <p className="mb-1 ml-4 text-gray-500">
              Report Generation Time:{' '}
              {requestDuration >= 60000
                ? `${Math.floor(requestDuration / 60000)} min ${((requestDuration % 60000) / 1000).toFixed(2)} sec`
                : `${(requestDuration / 1000).toFixed(2)} seconds`}
            </p>
          )}
          {isChartLoading ? (
            <div className="flex justify-center items-center flex-col p-10">
              <WolfLoader customStyles={{ height: '50px', width: '100px' }} customText="" />
              <div className="text-blue-500 font-bold mt-10">Rendering Chart...</div>
            </div>
          ) : (
            <ResponsiveContainer width="100%" height={800}>
              <BarChart data={transformedData} layout="vertical" margin={{ top: 20, right: 30, left: 20, bottom: 5 }}>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis type="number" tickFormatter={(tick) => `${(tick / 1000) % 1 === 0 ? tick / 1000 : (tick / 1000).toFixed(2)} seconds`} />
                <YAxis
                  dataKey="startedDateTime"
                  type="category"
                  tickFormatter={(tick) => new Date(tick).toLocaleTimeString()} // Format the date/time
                  width={100} // Adjust the width as needed
                  reversed={true}
                />
                <Tooltip
                  content={({ active, payload }) => {
                    if (active && payload && payload.length) {
                      const data = payload[0].payload;
                      return (
                        <div style={{ backgroundColor: '#fff', border: '1px solid #ccc', padding: '10px' }}>
                          <p>{`Operation: ${data.operationName}`}</p>
                          <p>{`Start Time: ${new Date(data.startedDateTime).toLocaleTimeString()}`}</p>
                          <p>{`End Time: ${new Date(data.endTime).toLocaleTimeString()}`}</p>
                          <p>{`Duration: ${
                            data.time === -1 ? 'Errored out' : data.time > 999 ? (data.time / 1000).toFixed(2) + ' sec' : data.time + ' ms'
                          }`}</p>
                        </div>
                      );
                    }
                    return null;
                  }}
                />
                <Legend
                  content={() => (
                    <ul style={{ listStyleType: 'none', padding: 0 }}>
                      <li>
                        <span style={{ marginRight: 5 }}>🟩</span>
                        <span style={{ marginRight: 30 }}>0 - 14 sec</span>
                        <span style={{ marginRight: 5 }}>🟨</span>
                        <span style={{ marginRight: 30 }}>15 sec or greater</span>
                        <span style={{ marginRight: 5 }}>🟥</span>
                        <span style={{ marginRight: 30 }}>30 sec or greater</span>
                        <span style={{ marginRight: 5 }}>🟪</span>
                        <span>Failed Request</span>
                      </li>
                    </ul>
                  )}
                />
                <Bar dataKey="duration" isAnimationActive={false}>
                  {transformedData.map((entry, index) => {
                    const color =
                      entry.time >= 15000 && entry.time < 30000
                        ? 'yellow'
                        : entry.time >= 30000 && entry.time !== -1
                        ? 'red'
                        : entry.time === -1
                        ? 'purple'
                        : 'green';

                    return <Cell key={`cell-${index}`} fill={color} />;
                  })}
                </Bar>
              </BarChart>
            </ResponsiveContainer>
          )}
          <div className="overflow-x-auto pt-4">
            <table className="min-w-full bg-white border border-gray-200 text-xs">
              <caption className="text-lg font-bold mb-2">Operation Summary</caption>
              <thead>
                <tr>
                  <th className="px-2 py-1 border-b border-gray-200 tooltip relative">
                    Operation Name
                    <span className="absolute bottom-full left-1/2 transform -translate-x-1/2 mb-1 w-32 bg-black text-white text-center rounded py-1 opacity-0 transition-opacity duration-300 tooltiptext">
                      The name of the operation
                    </span>
                  </th>
                  <th className="px-2 py-1 border-b border-gray-200 tooltip relative">
                    Total Calls
                    <span className="absolute bottom-full left-1/2 transform -translate-x-1/2 mb-1 w-32 bg-black text-white text-center rounded py-1 opacity-0 transition-opacity duration-300 tooltiptext">
                      Total number of calls made
                    </span>
                  </th>
                  <th className="px-2 py-1 border-b border-gray-200 tooltip relative">
                    Workflows That Called It
                    <span className="absolute bottom-full left-1/2 transform -translate-x-1/2 mb-1 w-32 bg-black text-white text-center rounded py-1 opacity-0 transition-opacity duration-300 tooltiptext">
                      Number of workflows
                    </span>
                  </th>
                  <th className="px-2 py-1 border-b border-gray-200 tooltip relative">
                    Retries
                    <span className="absolute bottom-full left-1/2 transform -translate-x-1/2 mb-1 w-32 bg-black text-white text-center rounded py-1 opacity-0 transition-opacity duration-300 tooltiptext">
                      Number of retries
                    </span>
                  </th>
                  <th className="px-2 py-1 border-b border-gray-200 tooltip relative">
                    Failures
                    <span className="absolute bottom-full left-1/2 transform -translate-x-1/2 mb-1 w-32 bg-black text-white text-center rounded py-1 opacity-0 transition-opacity duration-300 tooltiptext">
                      Number of failures
                    </span>
                  </th>
                </tr>
              </thead>
              <tbody>
                {sortedOperationSummary.map((data, index) => (
                  <tr key={index} className="hover:bg-gray-100">
                    <td className="px-2 py-1 border-b border-gray-200">{data.operationName}</td>
                    <td className="px-2 py-1 border-b border-gray-200">{data.totalCalls}</td>
                    <td className="px-2 py-1 border-b border-gray-200">{data.workflowsCount}</td>
                    <td className="px-2 py-1 border-b border-gray-200">{data.retries}</td>
                    <td className="px-2 py-1 border-b border-gray-200">{data.failures}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </>
      )}
    </div>
  );
}
