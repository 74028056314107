import { FileDownload } from '@mui/icons-material';
import { Button } from '@mui/material';
import dayjs from 'dayjs';
import isoWeek from 'dayjs/plugin/isoWeek';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import ExcelJS from 'exceljs';

dayjs.extend(isoWeek);
dayjs.extend(utc);
dayjs.extend(timezone);

const getExcelColumnName = (columnNumber) => {
  let columnName = '';
  while (columnNumber > 0) {
    let modulo = (columnNumber - 1) % 26;
    columnName = String.fromCharCode(65 + modulo) + columnName;
    columnNumber = Math.floor((columnNumber - modulo) / 26);
  }
  return columnName;
};

// Safe merge cells function
const safeMergeCells = (worksheet, range) => {
  const [start, end] = range.split(':');
  if (start === end) {
    // No need to merge if it's a single cell
    return;
  }

  const startCell = worksheet.getCell(start);
  if (!startCell.isMerged) {
    try {
      worksheet.mergeCells(range);
    } catch (error) {
      console.warn(`Failed to merge cells ${range}:`, error.message);
    }
  }
};

// Add this function to format the date range
const formatDateRange = (startDate, endDate) => {
  return `${dayjs(startDate).format('MMM D, YYYY')} to ${dayjs(endDate).format('MMM D, YYYY')}`;
};

export default function ExportFlakinessReportButton({ filteredSuiteData, customerNameOrId }) {
  const downloadXlsx = async () => {
    const workbook = new ExcelJS.Workbook();
    const usedSheetNames = new Set();

    // Helper function to sanitize trigger names for worksheet names
    const sanitizeSheetName = (name) => {
      let sanitized = name.replace(/[*?:\\/[\]]/g, '|').substring(0, 31);

      // Special handling for European Time Zone triggers
      if (sanitized.startsWith('CI_Weekly_(European_Time_Zone)_')) {
        const dayNumber = sanitized.match(/\d+/)[0];
        const day = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'][parseInt(dayNumber) - 1];
        sanitized = `EU_TZ_${day}`;
      }

      // Ensure unique sheet names
      let uniqueSanitized = sanitized;
      let counter = 1;
      while (usedSheetNames.has(uniqueSanitized)) {
        uniqueSanitized = `${sanitized.substring(0, 28)}_${counter}`.substring(0, 31);
        counter++;
      }
      usedSheetNames.add(uniqueSanitized);

      return uniqueSanitized;
    };

    // Find start and end dates
    let startDate = dayjs(filteredSuiteData[0].startTime);
    let endDate = dayjs(filteredSuiteData[0].startTime);

    filteredSuiteData.forEach((suite) => {
      const suiteDate = dayjs(suite.startTime);
      if (suiteDate.isBefore(startDate)) {
        startDate = suiteDate;
      }
      if (suiteDate.isAfter(endDate)) {
        endDate = suiteDate;
      }
    });

    startDate = startDate.tz('America/Los_Angeles');
    endDate = endDate.tz('America/Los_Angeles');

    // Group data by weeks
    const weeklyData = groupDataByWeeks(filteredSuiteData);

    // Create a sheet for each trigger
    Object.entries(weeklyData).forEach(([triggerName, triggerWeeks]) => {
      const sanitizedTriggerName = sanitizeSheetName(triggerName);
      const worksheet = workbook.addWorksheet(sanitizedTriggerName);

      // Add title at the top left
      const titleCell = worksheet.getCell('A1');
      titleCell.value = `${customerNameOrId} Flake Report: ${formatDateRange(startDate, endDate)}`;
      titleCell.font = { size: 16, bold: true };
      titleCell.alignment = { vertical: 'middle' };

      // Add trigger name
      const triggerNameCell = worksheet.getCell('A3');
      triggerNameCell.value = `Trigger: ${triggerName}`;
      triggerNameCell.font = { size: 14, bold: true };
      triggerNameCell.alignment = { vertical: 'middle' };

      // Process weeks for this sheet
      let columnIndex = 1;
      triggerWeeks.forEach((weekData, index) => {
        const previousWeekData = index < triggerWeeks.length - 1 ? triggerWeeks[index + 1] : null;
        columnIndex = addWeeklyTable(worksheet, weekData, columnIndex, index, previousWeekData);
      });

      applyWorksheetStyles(worksheet);
    });

    // Generate XLSX file
    const buffer = await workbook.xlsx.writeBuffer();
    const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
    const url = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.download = `${customerNameOrId} Flake Report.xlsx`;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    URL.revokeObjectURL(url);
  };

  const groupDataByWeeks = (suiteData) => {
    const weeklyData = {};

    suiteData.forEach((suite) => {
      const weekStart = dayjs(suite.startTime).tz('America/Los_Angeles').startOf('isoWeek');
      const weekKey = weekStart.format('YYYY-MM-DD');
      let triggerName = suite.triggerName || 'Manual';

      // Group PR suites together
      if (triggerName.toLowerCase().startsWith('pr deploy') || triggerName.toLowerCase().startsWith('pr test')) {
        triggerName = 'PR Deployments';
      }

      if (!weeklyData[triggerName]) {
        weeklyData[triggerName] = {};
      }

      if (!weeklyData[triggerName][weekKey]) {
        weeklyData[triggerName][weekKey] = [];
      }

      weeklyData[triggerName][weekKey].push(suite);
    });

    // Sort weeks and calculate flake scores
    Object.keys(weeklyData).forEach((trigger) => {
      weeklyData[trigger] = Object.entries(weeklyData[trigger])
        .sort(([weekA], [weekB]) => weekB.localeCompare(weekA))
        .map(([week, suites]) => ({
          week,
          meanFlakeScore: calculateMeanFlakeScore(suites),
          medianFlakeScore: calculateMedianFlakeScore(suites),
          workflowFlakiness: calculateWorkflowFlakiness(suites),
        }));
    });

    return weeklyData;
  };

  const calculateMeanFlakeScore = (suites) => {
    const totalFlakePercent = suites.reduce((sum, suite) => sum + suite.flakePercent, 0);
    return totalFlakePercent / suites.length / 100;
  };

  const calculateMedianFlakeScore = (suites) => {
    const flakeScores = suites.map((suite) => suite.flakePercent);
    const sorted = flakeScores.slice().sort((a, b) => a - b);
    const middle = Math.floor(sorted.length / 2);

    if (sorted.length % 2 === 0) {
      return (sorted[middle - 1] + sorted[middle]) / 2 / 100;
    }

    return sorted[middle] / 100;
  };

  const calculateWorkflowFlakiness = (suites) => {
    const workflowFlakiness = {};

    suites.forEach((suite) => {
      Object.entries(suite.runsByLastAttemptStatus).forEach(([status, workflows]) => {
        workflows.forEach((workflow) => {
          const workflowName = workflow.name.split(/:(.+)/)[1];
          if (!workflowFlakiness[workflowName]) {
            workflowFlakiness[workflowName] = { totalFlakiness: 0, count: 0 };
          }
          workflowFlakiness[workflowName].totalFlakiness += getWorkflowFlakiness(status);
          workflowFlakiness[workflowName].count += 1;
        });
      });
    });

    return Object.entries(workflowFlakiness)
      .map(([name, data]) => ({
        name,
        flakiness: data.totalFlakiness / data.count,
      }))
      .sort((a, b) => b.flakiness - a.flakiness);
  };

  const addWeeklyTable = (worksheet, weekData, columnIndex, weekIndex, previousWeekData) => {
    const startRow = 5;
    const startColumn = getExcelColumnName(columnIndex);
    const endColumn = getExcelColumnName(columnIndex + 1);

    // Add week title
    safeMergeCells(worksheet, `${startColumn}${startRow}:${endColumn}${startRow}`);
    const weekTitleCell = worksheet.getCell(`${startColumn}${startRow}`);
    weekTitleCell.value = `Week of ${dayjs(weekData.week).format('DD MMM YYYY')} (Mon-Sun)`;
    weekTitleCell.font = { size: 14, bold: true };
    weekTitleCell.alignment = { horizontal: 'center', vertical: 'middle' };

    // Add mean and median flake scores
    safeMergeCells(worksheet, `${startColumn}${startRow + 1}:${endColumn}${startRow + 1}`);
    const flakeScoreCell = worksheet.getCell(`${startColumn}${startRow + 1}`);
    flakeScoreCell.value = `Mean: ${weekData.meanFlakeScore.toFixed(2)} | Median: ${weekData.medianFlakeScore.toFixed(2)}`;
    flakeScoreCell.font = { size: 12, bold: true, color: { argb: 'FFFFFFFF' } };
    flakeScoreCell.alignment = { horizontal: 'center', vertical: 'middle' };
    flakeScoreCell.fill = {
      type: 'pattern',
      pattern: 'solid',
      fgColor: { argb: getFlakinessColor(weekData.meanFlakeScore) },
    };

    let currentRow = startRow + 3;

    // Add comparison tables if previous week data is available
    if (previousWeekData) {
      const comparisonData = compareWeeks(weekData, previousWeekData);

      // Add Most Improved Workflows table
      currentRow = addComparisonTable(worksheet, comparisonData.improved, startColumn, currentRow, "Most Improved Workflows") + 2;

      // Add Most Deteriorated Workflows table
      currentRow = addComparisonTable(worksheet, comparisonData.deteriorated, startColumn, currentRow, "Most Deteriorated Workflows") + 2;
    }

    // Add table headers
    const headerRow = worksheet.getRow(currentRow);
    headerRow.getCell(startColumn).value = 'Workflow Name';
    headerRow.getCell(endColumn).value = 'Flake Score';
    [startColumn, endColumn].forEach((col) => {
      const cell = headerRow.getCell(col);
      cell.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: 'FFF5F5F5' },
      };
      cell.font = { bold: true };
      cell.alignment = { horizontal: 'center', vertical: 'middle', wrapText: true };
    });

    // Add workflow data
    weekData.workflowFlakiness.forEach((workflow, index) => {
      const row = worksheet.getRow(currentRow + 1 + index);
      const nameCell = row.getCell(startColumn);
      const scoreCell = row.getCell(endColumn);

      nameCell.value = workflow.name;
      nameCell.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: 'FFF5F5F5' },
      };
      nameCell.font = { bold: true };
      nameCell.alignment = { horizontal: 'left', vertical: 'middle', wrapText: true };

      scoreCell.value = workflow.flakiness;
      scoreCell.numFmt = '0.00';
      scoreCell.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: getFlakinessColor(workflow.flakiness) },
      };
      scoreCell.font = { color: { argb: 'FFFFFFFF' } };
      scoreCell.alignment = { horizontal: 'center', vertical: 'middle' };
    });

    // Update the column width setting
    worksheet.getColumn(startColumn).width = 70;
    worksheet.getColumn(endColumn).width = 30;

    // Add an empty column after the
    worksheet.getColumn(getExcelColumnName(columnIndex + 2)).width = 5;

    // Return the next column index
    return columnIndex + 3;
  };

  const compareWeeks = (currentWeek, previousWeek) => {
    const currentWorkflows = new Map(currentWeek.workflowFlakiness.map((w) => [w.name, w.flakiness]));
    const previousWorkflows = new Map(previousWeek.workflowFlakiness.map((w) => [w.name, w.flakiness]));

    const differences = [];

    currentWorkflows.forEach((currentFlakiness, workflowName) => {
      const previousFlakiness = previousWorkflows.get(workflowName) || currentFlakiness;
      differences.push({
        name: workflowName,
        difference: currentFlakiness - previousFlakiness,
      });
    });

    differences.sort((a, b) => b.difference - a.difference); // Sort from highest to lowest difference

    return {
      deteriorated: differences.slice(0, 10), // Most deteriorated (highest positive difference)
      improved: differences.slice(-10).reverse(), // Most improved (highest negative difference)
    };
  };

  const addComparisonTable = (worksheet, data, startColumn, startRow, title) => {
    const endColumn = String.fromCharCode(startColumn.charCodeAt(0) + 1);

    // Add title
    worksheet.mergeCells(`${startColumn}${startRow}:${endColumn}${startRow}`);
    const titleCell = worksheet.getCell(`${startColumn}${startRow}`);
    titleCell.value = title;
    titleCell.font = { size: 12, bold: true };
    titleCell.alignment = { horizontal: 'center', vertical: 'middle' };
    titleCell.fill = {
      type: 'pattern',
      pattern: 'solid',
      fgColor: { argb: 'FFF5F5F5' },
    };

    // Add headers
    const headerRow = worksheet.getRow(startRow + 1);
    headerRow.getCell(startColumn).value = 'Workflow Name';
    headerRow.getCell(endColumn).value = 'Delta';
    [startColumn, endColumn].forEach((col) => {
      const cell = headerRow.getCell(col);
      cell.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: 'FFF5F5F5' },
      };
      cell.font = { bold: true };
      cell.alignment = { horizontal: 'center', vertical: 'middle', wrapText: true };
    });

    // Add data
    data.forEach((workflow, index) => {
      const row = worksheet.getRow(startRow + 2 + index);
      const nameCell = row.getCell(startColumn);
      const deltaCell = row.getCell(endColumn);

      nameCell.value = workflow.name;
      nameCell.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: 'FFF5F5F5' },
      };
      nameCell.alignment = { horizontal: 'left', vertical: 'middle', wrapText: true };

      deltaCell.value = workflow.difference;
      deltaCell.numFmt = '+0.00;-0.00;0.00';
      deltaCell.alignment = { horizontal: 'center', vertical: 'middle' };
      deltaCell.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: getDeltaColor(workflow.difference) },
      };
      deltaCell.font = { color: { argb: 'FFFFFFFF' } };
    });

    return startRow + data.length + 1; // Return the next available row
  };

  const getDeltaColor = (delta) => {
    const absDelta = Math.abs(delta);
    if (delta < 0) {  // Improvement (green)
      if (absDelta <= 0.2) return '90EE90';  // Light green
      if (absDelta <= 0.5) return '32CD32';  // Lime green
      if (absDelta <= 0.8) return '228B22';  // Forest green
      return '006400';  // Dark green
    } else {  // Deterioration (orange)
      if (absDelta <= 0.2) return 'FFA07A';  // Light salmon
      if (absDelta <= 0.5) return 'FF8C00';  // Dark orange
      if (absDelta <= 0.8) return 'FF4500';  // Orange red
      return 'FF0000';  // Red
    }
  };

  const applyWorksheetStyles = (worksheet) => {
    worksheet.properties.defaultRowHeight = 25;

    worksheet.eachRow((row) => {
      row.eachCell((cell) => {
        if (!cell.style.border) {
          cell.border = {
            top: { style: 'thin' },
            left: { style: 'thin' },
            bottom: { style: 'thin' },
            right: { style: 'thin' },
          };
        }
        if (!cell.style.alignment) {
          cell.alignment = { vertical: 'middle' };
        }
      });
    });
  };

  const getFlakinessColor = (flakiness) => {
    if (flakiness <= 0.2) return '4CAF50'; // Dark green
    if (flakiness <= 0.5) return 'FFA000'; // Dark amber
    if (flakiness <= 0.8) return 'F57C00'; // Dark orange
    return 'D32F2F'; // Dark red
  };

  const getWorkflowFlakiness = (status) => {
    const flakinessMap = {
      passedFirst: 0,
      passedSecond: 1,
      passedThird: 1,
      qaeFlake: 1,
      qaeTriage: 0,
      autoTriaged: 0,
      newlyBugged: 0,
      maintenance: 0,
      doNotInvestigate: 0,
      canceled: 0,
      failed: 0,
      supersededRuns: 0,
    };
    return flakinessMap[status] || 0;
  };

  return (
    <Button
      variant="contained"
      sx={{
        backgroundColor: '#2f2fc1',
        '&:hover': {
          backgroundColor: '#1e1ea1',
        },
        marginLeft: '10px',
        padding: '6px 12px',
        fontSize: '0.8rem',
        minWidth: '160px',
      }}
      startIcon={<FileDownload />}
      onClick={downloadXlsx}
    >
      Export Flake Report
    </Button>
  );
}
