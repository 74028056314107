import { useState } from 'react';
import * as Yup from 'yup';
import { sendMultiPartPostRequest } from '../../utils/network';
import { getAllJobSources } from '../HiringInternal/api';
import { useQuery } from '@tanstack/react-query';

const initialFormState = {
  firstName: '',
  lastName: '',
  email: '',
  location: '',
  referralName: '',
  jobSource: '',
  videoSubmission: '',
  instituteName: '',
  file: null,
};

export default function UploadAssignmentForm({ setShowForm, setServerError }) {
  const [formState, setFormState] = useState(initialFormState);
  const [isReferred, setIsReferred] = useState(false);
  const [fileInputKey, setFileInputKey] = useState('');
  const [errors, setErrors] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [hasJobSourcesBeenFetched, setHasJobSourcesBeenFetched] = useState(false);

  const { data: jobSources, isLoading, isFetching, isError, refetch } = useQuery({
    queryKey: ['allJobSources'],
    queryFn: getAllJobSources,
    select: (data) => data.map((sources) => sources.sourceName),
    enabled: false,
  });

  const formValidationSchema = Yup.object({
    firstName: Yup.string().min(2, "First name must be at least 2 characters").required('First name is required'),
    lastName: Yup.string().min(2, "Last name must be at least 2 characters").required('Last name is required'),
    email: Yup.string().email('Invalid email format').min(5, "Email must be at least 5 characters").required('Email is required'),
    location: Yup.string().min(2, "Location must be at least 2 characters").required('Location is required'),
    referralName: Yup.string(),
    jobSource: Yup.string().required('Job Source is required'),
    videoSubmission: Yup.string().url('Enter a valid URL!').test(
      'is-loom-url',
      'URL must be a Loom video link!',
      (value) => {
        if (!value) return false;
        try {
          const url = new URL(value);
          return url.host === 'loom.com' || url.hostname === 'www.loom.com';
        } catch (err) {
          return false;
        }
      },
    ).required('A video walkthrough is required'),
    instituteName: Yup.string(),
    file: Yup.mixed()
      .required('File is required')
      .test('fileSize', 'File exceeds the maximum supported size of 25 MB', (value) => {
        // @ts-ignore
        return value && value.size <= 1024 * 1024 * 25;
      })
      .test('fileType', 'Unsupported file format, only zip files are accepted', (value) => value.type === 'application/zip' || value.type === 'application/x-zip' || value.type === 'application/x-zip-compressed' || value.type === 'application/x-compress' || value.type === 'application/x-compressed' || value.type === 'multipart/x-zip' || value.type === 'application/gzip' || value.type === 'application/x-7z-compressed' || value.type === 'application/vnd.rar' || value.type === 'application/x-tar'),
  });

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true); // Set submitting state to true when button is clicked

    try {
      // Validate form using Yup
      await formValidationSchema.validate(formState, { abortEarly: false });

      setShowForm(false);

      // Prepare FormData by iterating over formState directly
      const formData = new FormData();
      Object.entries(formState).forEach(([key, value]) => {
        formData.append(key, value);
      });

      // Send form data via POST request
      const result = await sendMultiPartPostRequest('/hiring-public/submission', formData);
      const { success } = result;

      if (!success) {
        setServerError(true);
        return;
      }

      // Batch reset operations (formState, fileInputKey, errors, submitted state, and toast)
      setFormState(initialFormState);
      setFileInputKey(Math.random().toString(36)); // Reset file input
      setErrors({});
    } catch (error) {
      // Handle validation errors from Yup
      const validationErrors = error.inner.reduce((acc, err) => {
        acc[err.path] = err.message;
        return acc;
      }, {});

      // Set validation errors in state
      setErrors(validationErrors);
    } finally {
      setIsSubmitting(false); // Set submitting state back to false after submission
    }
  };


  const handleCheckboxChanges = (e) => {
    setIsReferred(e.target.checked);
  };

  const handleInputChanges = (e) => {
    if (e.target.name === 'file') {
      setFormState((prevState) => {
        return { ...prevState, [e.target.name]: e.target.files[0] };
      });
    } else {
      setFormState((prevState) => {
        return { ...prevState, [e.target.name]: e.target.value };
      });
    }
  };

  const handleJobSourceInteraction = () => {
    if (!hasJobSourcesBeenFetched) {
      refetch();
      setHasJobSourcesBeenFetched(true);
    }
  };

  return (
    <div className="flex flex-col w-5/6 items-center">
      <form className="w-5/6 flex flex-col" onSubmit={handleSubmit}>
        {/* First Flex Row */}
        <section className="flex flex-wrap justify-between w-full">
          <div className="flex flex-col w-[48%] inputs">
            <label className="font-semibold" htmlFor="firstName">
              First Name:
            </label>
            <input
              className="m-[10px 0px] text-black bg-gray-50 border border-gray-300 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
              id="firstName"
              type="text"
              name="firstName"
              placeholder="First Name"
              onChange={handleInputChanges}
              value={formState.firstName}
            />
            {/* @ts-ignore */}
            {errors?.firstName && <div className="text-[#ff4c4c] formError">{errors?.firstName}</div>}
          </div>

          <div className="flex flex-col w-[48%] inputs">
            <label className="font-semibold" htmlFor="lastName">
              Last Name:
            </label>
            <input
              className="m-[10px 0px] text-black bg-gray-50 border border-gray-300 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
              id="lastName"
              type="text"
              name="lastName"
              placeholder="Last Name"
              value={formState.lastName}
              onChange={handleInputChanges}
            />
            {/* @ts-ignore */}
            {errors?.lastName && <div className="text-[#ff4c4c] formError">{errors?.lastName}</div>}
          </div>
        </section>

        {/* Second Flex Row */}
        <section className="flex flex-wrap justify-between w-full mt-4">
          <div className="flex flex-col w-[48%] inputs">
            <label className="font-semibold" htmlFor="email">
              Email:
            </label>
            <input
              className="m-[10px 0px] text-black bg-gray-50 border border-gray-300 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
              id="email"
              type="email"
              autoComplete="email"
              name="email"
              placeholder="Enter your email"
              value={formState.email}
              onChange={handleInputChanges}
            />
            {/* @ts-ignore */}
            {errors?.email && <div className="text-[#ff4c4c] formError">{errors?.email}</div>}
          </div>

          <div className="flex flex-col w-[48%] inputs">
            <label className="font-semibold" htmlFor="location">
              Location (City, State/Province, Country):
            </label>
            <input
              className="m-[10px 0px] text-black bg-gray-50 border border-gray-300 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
              id="location"
              type="text"
              name="location"
              placeholder="(e.g., New York, NY, USA)"
              value={formState.location}
              onChange={handleInputChanges}
            />
            {/* @ts-ignore */}
            {errors?.location && <div className="text-[#ff4c4c] formError">{errors?.location}</div>}
          </div>
        </section>

        {/* Third Flex Row */}
        <section className="flex flex-wrap justify-between w-full mt-4">
          <div className="flex flex-col w-[48%] inputs">
            <label className="font-semibold" htmlFor="jobSource">
              Job Source:
            </label>
            <select
              className={`m-[10px 0px] bg-gray-50 border border-gray-300 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 ${formState.jobSource ? 'text-black' : 'text-gray-500'
                }`}
              id="jobSource"
              name="jobSource"
              onChange={handleInputChanges}
              value={formState.jobSource === '' ? 'Where did you find this job' : formState.jobSource}
              onClick={handleJobSourceInteraction}
              onFocus={handleJobSourceInteraction}
            >

              {
                !isLoading && !isFetching && !jobSources &&
                <option value="Where did you find this job" disabled>
                  Where did you find this job
                </option>
              }

              <option value="" disabled>
                {isLoading || isFetching
                  ? 'Loading job sources...'
                  : "Where did you find this job"}
              </option>

              {!isLoading && !isFetching && !isError && jobSources && jobSources.map((jobSource, i) => (
                <option key={i} value={jobSource.toLowerCase()}>
                  {jobSource}
                </option>
              ))}
            </select>
            {/* @ts-ignore */}
            {errors?.jobSource && <div className="text-[#ff4c4c] formError">{errors?.jobSource}</div>}
          </div>

          <div className="flex flex-col w-[48%] inputs">
            <label className="font-semibold" htmlFor="bootcamp">
              Institute name (optional):
            </label>
            <input
              className="m-[10px 0px] text-black bg-gray-50 border border-gray-300 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
              id="bootcamp"
              type="text"
              name="instituteName"
              placeholder="Enter the name of the Bootcamp or University"
              value={formState.instituteName}
              onChange={handleInputChanges}
            />
            {/* @ts-ignore */}
            {errors?.instituteName && <div className="text-[#ff4c4c] formError">{errors?.instituteName}</div>}
          </div>
        </section>

        <div className="flex flex-col inputs mt-[3%]">
          <label className="font-semibold" htmlFor="videoSubmission">
            Code walkthrough link:
          </label>
          <input
            className={`m-[10px 0px] text-black bg-gray-50 border border-gray-300 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5`}
            id="videoSubmission"
            type="text"
            name="videoSubmission"
            placeholder="Link to code walkthrough"
            value={formState.videoSubmission}
            onChange={handleInputChanges}
          />
          {/* @ts-ignore */}
          {errors?.videoSubmission && <div className="text-[#ff4c4c] formError">{errors?.videoSubmission}</div>}
        </div>

        {/* Remaining fields */}
        <div className="flex flex-col w-full mt-6">
          <div className="flex items-center inputs">
            <input id="referral" type="checkbox" name="referral" className="m-[10px 0px] mr-3" onChange={handleCheckboxChanges} />
            <label className="font-semibold" htmlFor="referral">
              I was referred by a current QA Wolf employee
            </label>
          </div>

          {isReferred && (
            <div className="flex flex-col inputs mt-4">
              <label className="font-semibold" htmlFor="referee">
                Enter the name of the employee you were referred by:
              </label>
              <input
                className="text-black m-[10px 0px] bg-gray-50 border border-gray-300 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                id="referee"
                type="text"
                name="referralName"
                placeholder="Name"
                value={formState.referralName}
                onChange={handleInputChanges}
              />
              {/* @ts-ignore */}

              {errors?.referralName && <div className="text-[#ff4c4c] formError">{errors?.referralName}</div>}
            </div>
          )}

          <div className="flex flex-col mt-4">
            <label className="font-semibold mb-2 ml-4" htmlFor="file">
              Upload your take-home assessment
            </label>
            <input
              className="ml-4"
              id="file"
              type="file"
              name="file"
              key={fileInputKey || ''}
              style={{ color: 'white' }}
              onChange={handleInputChanges}
            />
            {/* @ts-ignore */}

            {errors?.file && <div className="text-[#ff4c4c] formError">{errors?.file}</div>}
          </div>

          <button
            type="submit"
            className={!isSubmitting ?
              "flex justify-center items-center self-center mt-6 rounded-md bg-gray-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
              : "flex justify-center items-center self-center mt-6 rounded-md bg-gray-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm cursor-not-allowed"
            }
            disabled={isSubmitting}
          >
            {!isSubmitting ? "Submit assignment" : "Submitting..."}
          </button>
        </div>
      </form>
    </div>
  );
}

