import _ from 'lodash';
import moment from 'moment/moment';

// Colors
// let taskInProgressColor = '#C7CF11';
// let taskBehindColor = '#F53D18';
// let taskNoDueDateColor = '#F5B918';
// let taskCompleteColor = '#70CF11';

export function statusIndicators(status) {
  const statusIndicators = {
    draft: '📝',
    needHelp: '🆘',
    readyForReview: '👀',
    inReview: '👀',
    bugged: '🐛',
    done: '✅',
    inProgress: '🟨',
    toDo: '⬜',
    scheduled: '🗓️',
    blocked: '🚫',
    ignore: '✋',
    changesRequested: '🔁',
    undefined: '❓',
  };
  return statusIndicators[status] || statusIndicators.undefined;
}

export function typeIndicators(type) {
  const taskTypeIndicators = {
    generic: '📌',
    message: '💬',
    projectPlan: '📓',
    triage: '👀',
    testCreation: '🧪',
    outline: '📝',
    testMaintenance: '🛠',
    testCoverageRequest: '📋',
    cr: '📋',
    promotion: '📈',
    bugVerification: '🐛',
  };

  return taskTypeIndicators[type] || taskTypeIndicators.undefined;
}

let maintenanceBgColor = '#0384fc';
/**
 * Transforms maintenance tasks into a format suitable for rendering in a Gantt chart.
 * @param {import('../../../../types').QAWTask[]} tasks - The array of maintenance tasks to transform.
 * @returns {Array} - The transformed tasks in the desired format.
 */
export function transformMaintenanceTasks(tasks) {
  let transformedTasks = [];

  let sortedTasks = _.orderBy(tasks, 'dueAt', 'asc');

  for (let task of sortedTasks) {
    let reportStartDate = moment(task.createdAt, 'YYYY-MM-DD');
    let reportDueDate = task.dueAt ? moment(task.dueAt, 'YYYY-MM-DD') : moment(reportStartDate).add(1, 'week');

    let issueId = task.issue.id;

    let childTasks = [];
    for (let childTask of task.childTasks) {
      let startDate = moment(childTask.createdAt, 'YYYY-MM-DD');
      let dueDate = childTask.dueAt ? moment(childTask.dueAt, 'YYYY-MM-DD') : moment(startDate).add(1, 'week');

      let taskId = childTask.id;
      let taskType = 'testMaintenance';
      let progress = childTask.status === 'done' ? 100 : 0;

      let formattedTask = {
        start: startDate.toDate(),
        end: dueDate.toDate(),
        name: `${typeIndicators(taskType)} ${statusIndicators(childTask.status)} ${childTask.workflow.name}`,
        id: taskId,
        progress: progress,
        type: 'task',
        project: issueId,
        hideChildren: true,
        styles: { backgroundColor: maintenanceBgColor },
      };

      childTasks.push(formattedTask);
    }

    let parentProgress = task.status === 'ignore' ? 100 : _.meanBy(childTasks, 'progress');

    let parentTask = {
      start: reportStartDate.toDate(),
      end: reportDueDate.toDate(),
      name: `${statusIndicators(task.status)} ${task.issue.name}`,
      id: issueId,
      type: 'project',
      progress: parentProgress,
      hideChildren: true,
      styles: { backgroundColor: maintenanceBgColor },
    };
    transformedTasks.push(parentTask);
    transformedTasks = transformedTasks.concat(childTasks);
  }

  return transformedTasks;
}

let genericBgColor = '#00C233';
/**
 * Transforms an array of tasks into a new format suitable for rendering in a Gantt chart.
 * @param {import('../../../../types').QAWTask[]} tasks - The array of tasks to transform.
 * @returns {Array} - The transformed tasks.
 */
export function transformGenericTasks(tasks) {
  let transformedTasks = [];

  let sortedTasks = _.orderBy(tasks, 'dueAt', 'asc');

  for (let task of sortedTasks) {
    let startDate = moment(task.createdAt, 'YYYY-MM-DD');
    let dueDate = task.dueAt ? moment(task.dueAt, 'YYYY-MM-DD') : moment(startDate).add(1, 'week');

    let parentProgress = task.status === 'done' ? 100 : 0;

    let parentTask = {
      start: startDate.toDate(),
      end: dueDate.toDate(),
      name: `${statusIndicators(task.status)} ${task.name}`,
      id: task.id,
      type: 'project',
      progress: parentProgress,
      hideChildren: true,
      styles: { backgroundColor: genericBgColor },
    };
    transformedTasks.push(parentTask);
  }

  return transformedTasks;
}

let crBgColor = '#c49a00';

/**
 * Transforms an array of tasks into a format suitable for a Gantt chart.
 * @param {import('../../../../types').QAWTask[]} tasks - The array of tasks to transform.
 * @returns {Array} The transformed tasks in the format required for a Gantt chart.
 */
export function transformCrTasks(tasks) {
  let transformedTasks = [];

  let sortedTasks = _.orderBy(tasks, 'dueAt', 'asc');
  for (let cr of sortedTasks) {
    let crStartDate = moment(cr.createdAt, 'YYYY-MM-DD').toDate();
    let crDueDate = cr.dueAt ? moment(cr.dueAt, 'YYYY-MM-DD').toDate() : moment(crStartDate).add(2, 'week').toDate();
    let crId = cr.issue.id;

    let childTasks = [];
    for (let childTask of cr.childTasks) {
      let startDate = moment(childTask.createdAt, 'YYYY-MM-DD');
      let dueDate = childTask.dueAt ? moment(childTask.dueAt, 'YYYY-MM-DD') : moment(startDate).add(2, 'week');

      let taskId = childTask.id;
      let taskType = childTask.type;
      let progress = childTask.status === 'done' ? 100 : 0;

      let formattedTask = {
        start: startDate.toDate(),
        end: dueDate.toDate(),
        name: `${typeIndicators(taskType)} ${statusIndicators(childTask.status)} ${childTask.workflow.name || cr.name}`,
        id: taskId,
        progress: progress,
        type: 'task',
        project: crId,
        hideChildren: true,
        styles: { backgroundColor: crBgColor },
      };

      childTasks.push(formattedTask);
    }

    let parentProgress = 100;
    if (childTasks.length && cr.status !== 'ignore' && cr.status !== 'done') {
      parentProgress = _.meanBy(childTasks, 'progress');
    }

    let parentTask = {
      start: crStartDate,
      end: crDueDate,
      name: `${statusIndicators(cr.status)} ${cr.name}`,
      id: crId,
      type: 'project',
      progress: parentProgress,
      hideChildren: true,
      styles: { backgroundColor: crBgColor },
    };
    transformedTasks.push(parentTask);
    transformedTasks = transformedTasks.concat(childTasks);
  }

  return transformedTasks;
}

export function getStartEndDateForProject(tasks, projectId) {
  if (tasks.length === 0) {
    let now = new Date();
    return [now, now];
  }

  const projectTasks = tasks.filter((t) => t.project === projectId);
  let start = projectTasks[0].start;
  let end = projectTasks[0].end;

  for (let i = 0; i < projectTasks.length; i++) {
    const task = projectTasks[i];
    if (start.getTime() > task.start.getTime()) {
      start = task.start;
    }
    if (end.getTime() < task.end.getTime()) {
      end = task.end;
    }
  }
  return [start, end];
}
