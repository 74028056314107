import { Button } from '@mui/material';
import CustomSnackbar from '../../shared/CustomSnackbar';
import { useQuery } from '@tanstack/react-query';
import { sendGetRequest, sendPostRequest } from '../../../utils/tanstackNetwork';
import { useMutation } from '@tanstack/react-query';
import { StoredUser } from '../../HowlUtilization/types';

export default function ProjectPlanButton({ teamId }: { teamId: string }) {
  const user: StoredUser = JSON.parse(localStorage.getItem('user') || '{}');

  const { data, isFetching, isLoading, isError, error, refetch } = useQuery({
    queryKey: ['clientSummary', teamId],
    queryFn: () => sendGetRequest(`/client-summaries/${teamId}`),
  });

  const taskMutation = useMutation({
    mutationKey: ['taskMutation'],
    mutationFn: ({ endpoint, taskId }: { endpoint: string; taskId: string }) => {
      return sendPostRequest(endpoint, { taskId });
    },
    onSuccess: () => {
      refetch();
    },
  });

  const approveProjectPlan = () => {
    taskMutation.mutate({ endpoint: '/done', taskId: data.projectPlanTask?.id });
  };

  // Show load spinner on initial load
  if (isFetching || isLoading) return <Button disabled={true}>{'Fetching project plans...'}</Button>;
  if (isError) return <Button disabled={true}>{error.message}</Button>;

  const ProjectPlanSnapshots = data.ProjectPlanSnapshots;
  let mostRecentUrl = 'https://www.notion.so/qawolf/';
  if (ProjectPlanSnapshots.length) {
    mostRecentUrl += ProjectPlanSnapshots.at(-1).notionPlanDatabaseId.replace(/-/g, '');
  }

  return (
    <>
      {data.projectPlanTask && user.qawId === data.projectPlanTask.assignedTo?.id ? (
        <Button variant="contained" onClick={approveProjectPlan} disabled={taskMutation.isPending || taskMutation.isSuccess}>
          {!taskMutation.isPending ? 'Approve Project Plan' : taskMutation.isSuccess ? 'Project Plan Approved' : 'Pending...'}
        </Button>
      ) : (
        <Button variant="contained" href={mostRecentUrl} target="_blank" rel="noreferrer">
          View Project Plan In Notion
        </Button>
      )}
      <CustomSnackbar open={taskMutation.isSuccess} message="Project Plan Approved!" />
      <CustomSnackbar open={taskMutation.isError} message="Error Approving Project Plan - Please reach out in the #dragons" />
    </>
  );
}
