const HiringFileExplorer = ({ files, onFileSelect }) => {
  return (
    <div
      style={{
        width: '15%',
        height: '100%',
        borderRight: '1px solid #ccc',
        padding: '10px',
        background: '#111',
        color: '#d4d4d4',
        overflowY: "auto",
      }}
    >
      <h3>File Explorer</h3>
      <ul style={{ listStyle: 'none', padding: 10 }}>
        {files.map((file, index) => (
          <li key={index} style={{ cursor: 'pointer', marginBottom: '5px' }}>
            <div onClick={() => onFileSelect(file)}>{file.name.split('/').pop().replace(/^._/, '')}</div>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default HiringFileExplorer;
